import { Authorizer } from "@authorizerdev/authorizer-js";

if (process.env.GATSBY_AUTHORIZER_URL === undefined) {
  throw new Error("GATSBY_AUTHORIZER_URL is missing");
}
if (process.env.GATSBY_AUTHORIZER_CLIENT_ID === undefined) {
  throw new Error("GATSBY_AUTHORIZER_CLIENT_ID is missing");
}

export const AUTH_CONFIG = {
  authorizerURL: process.env.GATSBY_AUTHORIZER_URL,
  redirectURL: typeof window !== "undefined" ? window.location.origin : "/",
  clientID: process.env.GATSBY_AUTHORIZER_CLIENT_ID,
};

export const AuthorizerClient = new Authorizer(AUTH_CONFIG);
