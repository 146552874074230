import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  String: string;
  /** The `Boolean` scalar type represents `true` or `false`. */
  Boolean: boolean;
  /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  Int: number;
  /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
  Float: number;
  date: any;
  float8: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type Interval = {
  interval: Scalars['String'];
};

export type PricesOutput = {
  currency: Scalars['String'];
  id: Scalars['String'];
  recurring: Interval;
  unit_amount: Scalars['Int'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Represents a client of a reservation */
export type Client = {
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  phone_number: Scalars['String'];
  /** An object relationship */
  property: Property;
  property_id: Scalars['uuid'];
  /** An array relationship */
  reservations: Array<Reservation>;
  /** An aggregate relationship */
  reservations_aggregate: Reservation_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** Represents a client of a reservation */
export type ClientReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


/** Represents a client of a reservation */
export type ClientReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};

/** aggregated selection of "client" */
export type Client_Aggregate = {
  aggregate?: Maybe<Client_Aggregate_Fields>;
  nodes: Array<Client>;
};

export type Client_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Aggregate_Bool_Exp_Count>;
};

export type Client_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Client_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client" */
export type Client_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Client_Max_Fields>;
  min?: Maybe<Client_Min_Fields>;
};


/** aggregate fields of "client" */
export type Client_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client" */
export type Client_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Max_Order_By>;
  min?: InputMaybe<Client_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client" */
export type Client_Arr_Rel_Insert_Input = {
  data: Array<Client_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'. */
export type Client_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Bool_Exp>>;
  _not?: InputMaybe<Client_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  reservations?: InputMaybe<Reservation_Bool_Exp>;
  reservations_aggregate?: InputMaybe<Reservation_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client" */
export enum Client_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientPkey = 'client_pkey'
}

/** input type for inserting data into table "client" */
export type Client_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']>;
  reservations?: InputMaybe<Reservation_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Client_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client" */
export type Client_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client" */
export type Client_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client" */
export type Client_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client>;
};

/** input type for inserting object relation for remote table "client" */
export type Client_Obj_Rel_Insert_Input = {
  data: Client_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** on_conflict condition type for table "client" */
export type Client_On_Conflict = {
  constraint: Client_Constraint;
  update_columns?: Array<Client_Update_Column>;
  where?: InputMaybe<Client_Bool_Exp>;
};

/** Ordering options when selecting data from "client". */
export type Client_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  reservations_aggregate?: InputMaybe<Reservation_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client */
export type Client_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "client" */
export enum Client_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "client" */
export type Client_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "client" */
export type Client_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "client" */
export enum Client_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Client_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** Expenses for a property */
export type Expense = {
  amount: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  currency_code: Scalars['String'];
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  property: Property;
  property_id: Scalars['uuid'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "expense" */
export type Expense_Aggregate = {
  aggregate?: Maybe<Expense_Aggregate_Fields>;
  nodes: Array<Expense>;
};

export type Expense_Aggregate_Bool_Exp = {
  count?: InputMaybe<Expense_Aggregate_Bool_Exp_Count>;
};

export type Expense_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Expense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Expense_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "expense" */
export type Expense_Aggregate_Fields = {
  avg?: Maybe<Expense_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Expense_Max_Fields>;
  min?: Maybe<Expense_Min_Fields>;
  stddev?: Maybe<Expense_Stddev_Fields>;
  stddev_pop?: Maybe<Expense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Expense_Stddev_Samp_Fields>;
  sum?: Maybe<Expense_Sum_Fields>;
  var_pop?: Maybe<Expense_Var_Pop_Fields>;
  var_samp?: Maybe<Expense_Var_Samp_Fields>;
  variance?: Maybe<Expense_Variance_Fields>;
};


/** aggregate fields of "expense" */
export type Expense_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Expense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "expense" */
export type Expense_Aggregate_Order_By = {
  avg?: InputMaybe<Expense_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Expense_Max_Order_By>;
  min?: InputMaybe<Expense_Min_Order_By>;
  stddev?: InputMaybe<Expense_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Expense_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Expense_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Expense_Sum_Order_By>;
  var_pop?: InputMaybe<Expense_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Expense_Var_Samp_Order_By>;
  variance?: InputMaybe<Expense_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "expense" */
export type Expense_Arr_Rel_Insert_Input = {
  data: Array<Expense_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Expense_On_Conflict>;
};

/** aggregate avg on columns */
export type Expense_Avg_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "expense" */
export type Expense_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "expense". All fields are combined with a logical 'AND'. */
export type Expense_Bool_Exp = {
  _and?: InputMaybe<Array<Expense_Bool_Exp>>;
  _not?: InputMaybe<Expense_Bool_Exp>;
  _or?: InputMaybe<Array<Expense_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency_code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "expense" */
export enum Expense_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExpensePkey = 'expense_pkey'
}

/** input type for incrementing numeric columns in table "expense" */
export type Expense_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "expense" */
export type Expense_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Expense_Max_Fields = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "expense" */
export type Expense_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Expense_Min_Fields = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "expense" */
export type Expense_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "expense" */
export type Expense_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Expense>;
};

/** on_conflict condition type for table "expense" */
export type Expense_On_Conflict = {
  constraint: Expense_Constraint;
  update_columns?: Array<Expense_Update_Column>;
  where?: InputMaybe<Expense_Bool_Exp>;
};

/** Ordering options when selecting data from "expense". */
export type Expense_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: expense */
export type Expense_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "expense" */
export enum Expense_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "expense" */
export type Expense_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Expense_Stddev_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "expense" */
export type Expense_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Expense_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "expense" */
export type Expense_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Expense_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "expense" */
export type Expense_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "expense" */
export type Expense_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Expense_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Expense_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Expense_Sum_Fields = {
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "expense" */
export type Expense_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "expense" */
export enum Expense_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Expense_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Expense_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Expense_Set_Input>;
  /** filter the rows which have to be updated */
  where: Expense_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Expense_Var_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "expense" */
export type Expense_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Expense_Var_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "expense" */
export type Expense_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Expense_Variance_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "expense" */
export type Expense_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "invoice" */
export type Invoice = {
  discount?: Maybe<Scalars['float8']>;
  id: Scalars['uuid'];
  number: Scalars['Int'];
  /** An object relationship */
  reservation?: Maybe<Reservation>;
  reservation_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "invoice" */
export type Invoice_Aggregate = {
  aggregate?: Maybe<Invoice_Aggregate_Fields>;
  nodes: Array<Invoice>;
};

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_Fields = {
  avg?: Maybe<Invoice_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Max_Fields>;
  min?: Maybe<Invoice_Min_Fields>;
  stddev?: Maybe<Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Sum_Fields>;
  var_pop?: Maybe<Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Variance_Fields>;
};


/** aggregate fields of "invoice" */
export type Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Invoice_Avg_Fields = {
  discount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'. */
export type Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Bool_Exp>>;
  discount?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  reservation?: InputMaybe<Reservation_Bool_Exp>;
  reservation_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice" */
export enum Invoice_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicePkey = 'invoice_pkey'
}

/** input type for incrementing numeric columns in table "invoice" */
export type Invoice_Inc_Input = {
  discount?: InputMaybe<Scalars['float8']>;
  number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice" */
export type Invoice_Insert_Input = {
  discount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['Int']>;
  reservation?: InputMaybe<Reservation_Obj_Rel_Insert_Input>;
  reservation_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Invoice_Max_Fields = {
  discount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Invoice_Min_Fields = {
  discount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "invoice" */
export type Invoice_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice>;
};

/** input type for inserting object relation for remote table "invoice" */
export type Invoice_Obj_Rel_Insert_Input = {
  data: Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** on_conflict condition type for table "invoice" */
export type Invoice_On_Conflict = {
  constraint: Invoice_Constraint;
  update_columns?: Array<Invoice_Update_Column>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice". */
export type Invoice_Order_By = {
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  reservation?: InputMaybe<Reservation_Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice */
export type Invoice_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoice" */
export enum Invoice_Select_Column {
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  ReservationId = 'reservation_id'
}

/** input type for updating data in table "invoice" */
export type Invoice_Set_Input = {
  discount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['Int']>;
  reservation_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Invoice_Stddev_Fields = {
  discount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Stddev_Pop_Fields = {
  discount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Stddev_Samp_Fields = {
  discount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "invoice" */
export type Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Stream_Cursor_Value_Input = {
  discount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['Int']>;
  reservation_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Invoice_Sum_Fields = {
  discount?: Maybe<Scalars['float8']>;
  number?: Maybe<Scalars['Int']>;
};

/** update columns of table "invoice" */
export enum Invoice_Update_Column {
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  ReservationId = 'reservation_id'
}

export type Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Var_Pop_Fields = {
  discount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Invoice_Var_Samp_Fields = {
  discount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Invoice_Variance_Fields = {
  discount?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  /** delete data from the table: "client" */
  delete_client?: Maybe<Client_Mutation_Response>;
  /** delete single row from the table: "client" */
  delete_client_by_pk?: Maybe<Client>;
  /** delete data from the table: "expense" */
  delete_expense?: Maybe<Expense_Mutation_Response>;
  /** delete single row from the table: "expense" */
  delete_expense_by_pk?: Maybe<Expense>;
  /** delete data from the table: "invoice" */
  delete_invoice?: Maybe<Invoice_Mutation_Response>;
  /** delete single row from the table: "invoice" */
  delete_invoice_by_pk?: Maybe<Invoice>;
  /** delete data from the table: "property" */
  delete_property?: Maybe<Property_Mutation_Response>;
  /** delete single row from the table: "property" */
  delete_property_by_pk?: Maybe<Property>;
  /** delete data from the table: "reservation" */
  delete_reservation?: Maybe<Reservation_Mutation_Response>;
  /** delete single row from the table: "reservation" */
  delete_reservation_by_pk?: Maybe<Reservation>;
  /** delete data from the table: "reservation_rooms" */
  delete_reservation_rooms?: Maybe<Reservation_Rooms_Mutation_Response>;
  /** delete single row from the table: "reservation_rooms" */
  delete_reservation_rooms_by_pk?: Maybe<Reservation_Rooms>;
  /** delete data from the table: "room" */
  delete_room?: Maybe<Room_Mutation_Response>;
  /** delete single row from the table: "room" */
  delete_room_by_pk?: Maybe<Room>;
  /** delete data from the table: "room_price" */
  delete_room_price?: Maybe<Room_Price_Mutation_Response>;
  /** delete single row from the table: "room_price" */
  delete_room_price_by_pk?: Maybe<Room_Price>;
  /** delete data from the table: "room_type" */
  delete_room_type?: Maybe<Room_Type_Mutation_Response>;
  /** delete single row from the table: "room_type" */
  delete_room_type_by_pk?: Maybe<Room_Type>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** insert data into the table: "client" */
  insert_client?: Maybe<Client_Mutation_Response>;
  /** insert a single row into the table: "client" */
  insert_client_one?: Maybe<Client>;
  /** insert data into the table: "expense" */
  insert_expense?: Maybe<Expense_Mutation_Response>;
  /** insert a single row into the table: "expense" */
  insert_expense_one?: Maybe<Expense>;
  /** insert data into the table: "invoice" */
  insert_invoice?: Maybe<Invoice_Mutation_Response>;
  /** insert a single row into the table: "invoice" */
  insert_invoice_one?: Maybe<Invoice>;
  /** insert data into the table: "property" */
  insert_property?: Maybe<Property_Mutation_Response>;
  /** insert a single row into the table: "property" */
  insert_property_one?: Maybe<Property>;
  /** insert data into the table: "reservation" */
  insert_reservation?: Maybe<Reservation_Mutation_Response>;
  /** insert a single row into the table: "reservation" */
  insert_reservation_one?: Maybe<Reservation>;
  /** insert data into the table: "reservation_rooms" */
  insert_reservation_rooms?: Maybe<Reservation_Rooms_Mutation_Response>;
  /** insert a single row into the table: "reservation_rooms" */
  insert_reservation_rooms_one?: Maybe<Reservation_Rooms>;
  /** insert data into the table: "room" */
  insert_room?: Maybe<Room_Mutation_Response>;
  /** insert a single row into the table: "room" */
  insert_room_one?: Maybe<Room>;
  /** insert data into the table: "room_price" */
  insert_room_price?: Maybe<Room_Price_Mutation_Response>;
  /** insert a single row into the table: "room_price" */
  insert_room_price_one?: Maybe<Room_Price>;
  /** insert data into the table: "room_type" */
  insert_room_type?: Maybe<Room_Type_Mutation_Response>;
  /** insert a single row into the table: "room_type" */
  insert_room_type_one?: Maybe<Room_Type>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** update data of the table: "client" */
  update_client?: Maybe<Client_Mutation_Response>;
  /** update single row of the table: "client" */
  update_client_by_pk?: Maybe<Client>;
  /** update multiples rows of table: "client" */
  update_client_many?: Maybe<Array<Maybe<Client_Mutation_Response>>>;
  /** update data of the table: "expense" */
  update_expense?: Maybe<Expense_Mutation_Response>;
  /** update single row of the table: "expense" */
  update_expense_by_pk?: Maybe<Expense>;
  /** update multiples rows of table: "expense" */
  update_expense_many?: Maybe<Array<Maybe<Expense_Mutation_Response>>>;
  /** update data of the table: "invoice" */
  update_invoice?: Maybe<Invoice_Mutation_Response>;
  /** update single row of the table: "invoice" */
  update_invoice_by_pk?: Maybe<Invoice>;
  /** update multiples rows of table: "invoice" */
  update_invoice_many?: Maybe<Array<Maybe<Invoice_Mutation_Response>>>;
  /** update data of the table: "property" */
  update_property?: Maybe<Property_Mutation_Response>;
  /** update single row of the table: "property" */
  update_property_by_pk?: Maybe<Property>;
  /** update multiples rows of table: "property" */
  update_property_many?: Maybe<Array<Maybe<Property_Mutation_Response>>>;
  /** update data of the table: "reservation" */
  update_reservation?: Maybe<Reservation_Mutation_Response>;
  /** update single row of the table: "reservation" */
  update_reservation_by_pk?: Maybe<Reservation>;
  /** update multiples rows of table: "reservation" */
  update_reservation_many?: Maybe<Array<Maybe<Reservation_Mutation_Response>>>;
  /** update data of the table: "reservation_rooms" */
  update_reservation_rooms?: Maybe<Reservation_Rooms_Mutation_Response>;
  /** update single row of the table: "reservation_rooms" */
  update_reservation_rooms_by_pk?: Maybe<Reservation_Rooms>;
  /** update multiples rows of table: "reservation_rooms" */
  update_reservation_rooms_many?: Maybe<Array<Maybe<Reservation_Rooms_Mutation_Response>>>;
  /** update data of the table: "room" */
  update_room?: Maybe<Room_Mutation_Response>;
  /** update single row of the table: "room" */
  update_room_by_pk?: Maybe<Room>;
  /** update multiples rows of table: "room" */
  update_room_many?: Maybe<Array<Maybe<Room_Mutation_Response>>>;
  /** update data of the table: "room_price" */
  update_room_price?: Maybe<Room_Price_Mutation_Response>;
  /** update single row of the table: "room_price" */
  update_room_price_by_pk?: Maybe<Room_Price>;
  /** update multiples rows of table: "room_price" */
  update_room_price_many?: Maybe<Array<Maybe<Room_Price_Mutation_Response>>>;
  /** update data of the table: "room_type" */
  update_room_type?: Maybe<Room_Type_Mutation_Response>;
  /** update single row of the table: "room_type" */
  update_room_type_by_pk?: Maybe<Room_Type>;
  /** update multiples rows of table: "room_type" */
  update_room_type_many?: Maybe<Array<Maybe<Room_Type_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_ClientArgs = {
  where: Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExpenseArgs = {
  where: Expense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Expense_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceArgs = {
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PropertyArgs = {
  where: Property_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReservationArgs = {
  where: Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reservation_RoomsArgs = {
  where: Reservation_Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservation_Rooms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RoomArgs = {
  where: Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Room_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Room_PriceArgs = {
  where: Room_Price_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Room_Price_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Room_TypeArgs = {
  where: Room_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Room_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_ClientArgs = {
  objects: Array<Client_Insert_Input>;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_OneArgs = {
  object: Client_Insert_Input;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExpenseArgs = {
  objects: Array<Expense_Insert_Input>;
  on_conflict?: InputMaybe<Expense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Expense_OneArgs = {
  object: Expense_Insert_Input;
  on_conflict?: InputMaybe<Expense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceArgs = {
  objects: Array<Invoice_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_OneArgs = {
  object: Invoice_Insert_Input;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PropertyArgs = {
  objects: Array<Property_Insert_Input>;
  on_conflict?: InputMaybe<Property_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_OneArgs = {
  object: Property_Insert_Input;
  on_conflict?: InputMaybe<Property_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReservationArgs = {
  objects: Array<Reservation_Insert_Input>;
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_OneArgs = {
  object: Reservation_Insert_Input;
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_RoomsArgs = {
  objects: Array<Reservation_Rooms_Insert_Input>;
  on_conflict?: InputMaybe<Reservation_Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_Rooms_OneArgs = {
  object: Reservation_Rooms_Insert_Input;
  on_conflict?: InputMaybe<Reservation_Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoomArgs = {
  objects: Array<Room_Insert_Input>;
  on_conflict?: InputMaybe<Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_OneArgs = {
  object: Room_Insert_Input;
  on_conflict?: InputMaybe<Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_PriceArgs = {
  objects: Array<Room_Price_Insert_Input>;
  on_conflict?: InputMaybe<Room_Price_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_Price_OneArgs = {
  object: Room_Price_Insert_Input;
  on_conflict?: InputMaybe<Room_Price_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_TypeArgs = {
  objects: Array<Room_Type_Insert_Input>;
  on_conflict?: InputMaybe<Room_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_Type_OneArgs = {
  object: Room_Type_Insert_Input;
  on_conflict?: InputMaybe<Room_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_ClientArgs = {
  _set?: InputMaybe<Client_Set_Input>;
  where: Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_By_PkArgs = {
  _set?: InputMaybe<Client_Set_Input>;
  pk_columns: Client_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_ManyArgs = {
  updates: Array<Client_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExpenseArgs = {
  _inc?: InputMaybe<Expense_Inc_Input>;
  _set?: InputMaybe<Expense_Set_Input>;
  where: Expense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Expense_By_PkArgs = {
  _inc?: InputMaybe<Expense_Inc_Input>;
  _set?: InputMaybe<Expense_Set_Input>;
  pk_columns: Expense_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Expense_ManyArgs = {
  updates: Array<Expense_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  pk_columns: Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_ManyArgs = {
  updates: Array<Invoice_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PropertyArgs = {
  _inc?: InputMaybe<Property_Inc_Input>;
  _set?: InputMaybe<Property_Set_Input>;
  where: Property_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_By_PkArgs = {
  _inc?: InputMaybe<Property_Inc_Input>;
  _set?: InputMaybe<Property_Set_Input>;
  pk_columns: Property_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_ManyArgs = {
  updates: Array<Property_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReservationArgs = {
  _inc?: InputMaybe<Reservation_Inc_Input>;
  _set?: InputMaybe<Reservation_Set_Input>;
  where: Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_By_PkArgs = {
  _inc?: InputMaybe<Reservation_Inc_Input>;
  _set?: InputMaybe<Reservation_Set_Input>;
  pk_columns: Reservation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_ManyArgs = {
  updates: Array<Reservation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_RoomsArgs = {
  _set?: InputMaybe<Reservation_Rooms_Set_Input>;
  where: Reservation_Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Rooms_By_PkArgs = {
  _set?: InputMaybe<Reservation_Rooms_Set_Input>;
  pk_columns: Reservation_Rooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Rooms_ManyArgs = {
  updates: Array<Reservation_Rooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoomArgs = {
  _inc?: InputMaybe<Room_Inc_Input>;
  _set?: InputMaybe<Room_Set_Input>;
  where: Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Room_By_PkArgs = {
  _inc?: InputMaybe<Room_Inc_Input>;
  _set?: InputMaybe<Room_Set_Input>;
  pk_columns: Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Room_ManyArgs = {
  updates: Array<Room_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Room_PriceArgs = {
  _inc?: InputMaybe<Room_Price_Inc_Input>;
  _set?: InputMaybe<Room_Price_Set_Input>;
  where: Room_Price_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Room_Price_By_PkArgs = {
  _inc?: InputMaybe<Room_Price_Inc_Input>;
  _set?: InputMaybe<Room_Price_Set_Input>;
  pk_columns: Room_Price_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Room_Price_ManyArgs = {
  updates: Array<Room_Price_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Room_TypeArgs = {
  _inc?: InputMaybe<Room_Type_Inc_Input>;
  _set?: InputMaybe<Room_Type_Set_Input>;
  where: Room_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Room_Type_By_PkArgs = {
  _inc?: InputMaybe<Room_Type_Inc_Input>;
  _set?: InputMaybe<Room_Type_Set_Input>;
  pk_columns: Room_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Room_Type_ManyArgs = {
  updates: Array<Room_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Represents a property */
export type Property = {
  address: Scalars['String'];
  bic?: Maybe<Scalars['String']>;
  check_in: Scalars['String'];
  check_out: Scalars['String'];
  /** An array relationship */
  clients: Array<Client>;
  /** An aggregate relationship */
  clients_aggregate: Client_Aggregate;
  created_at: Scalars['timestamptz'];
  currency_code: Scalars['String'];
  down_payment?: Maybe<Scalars['float8']>;
  email: Scalars['String'];
  /** An array relationship */
  expenses: Array<Expense>;
  /** An aggregate relationship */
  expenses_aggregate: Expense_Aggregate;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  legal_entity?: Maybe<Scalars['String']>;
  legal_entity_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pet_price?: Maybe<Scalars['float8']>;
  phone_number: Scalars['String'];
  /** An array relationship */
  reservations: Array<Reservation>;
  /** An aggregate relationship */
  reservations_aggregate: Reservation_Aggregate;
  /** An array relationship */
  room_types: Array<Room_Type>;
  /** An aggregate relationship */
  room_types_aggregate: Room_Type_Aggregate;
  /** An array relationship */
  rooms: Array<Room>;
  /** An aggregate relationship */
  rooms_aggregate: Room_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};


/** Represents a property */
export type PropertyClientsArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


/** Represents a property */
export type PropertyClients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


/** Represents a property */
export type PropertyExpensesArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


/** Represents a property */
export type PropertyExpenses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


/** Represents a property */
export type PropertyReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


/** Represents a property */
export type PropertyReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


/** Represents a property */
export type PropertyRoom_TypesArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


/** Represents a property */
export type PropertyRoom_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


/** Represents a property */
export type PropertyRoomsArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


/** Represents a property */
export type PropertyRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};

/** aggregated selection of "property" */
export type Property_Aggregate = {
  aggregate?: Maybe<Property_Aggregate_Fields>;
  nodes: Array<Property>;
};

export type Property_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Property_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Property_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Property_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Property_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Property_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Property_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Property_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Property_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Property_Aggregate_Bool_Exp_Var_Samp>;
};

export type Property_Aggregate_Bool_Exp_Avg = {
  arguments: Property_Select_Column_Property_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Corr = {
  arguments: Property_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Property_Select_Column_Property_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Property_Select_Column_Property_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Property_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Property_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Property_Select_Column_Property_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Property_Select_Column_Property_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Property_Aggregate_Bool_Exp_Max = {
  arguments: Property_Select_Column_Property_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Min = {
  arguments: Property_Select_Column_Property_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Property_Select_Column_Property_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Sum = {
  arguments: Property_Select_Column_Property_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Property_Select_Column_Property_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "property" */
export type Property_Aggregate_Fields = {
  avg?: Maybe<Property_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Property_Max_Fields>;
  min?: Maybe<Property_Min_Fields>;
  stddev?: Maybe<Property_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Sum_Fields>;
  var_pop?: Maybe<Property_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Var_Samp_Fields>;
  variance?: Maybe<Property_Variance_Fields>;
};


/** aggregate fields of "property" */
export type Property_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "property" */
export type Property_Aggregate_Order_By = {
  avg?: InputMaybe<Property_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Max_Order_By>;
  min?: InputMaybe<Property_Min_Order_By>;
  stddev?: InputMaybe<Property_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Property_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Property_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Property_Sum_Order_By>;
  var_pop?: InputMaybe<Property_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Property_Var_Samp_Order_By>;
  variance?: InputMaybe<Property_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "property" */
export type Property_Arr_Rel_Insert_Input = {
  data: Array<Property_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_On_Conflict>;
};

/** aggregate avg on columns */
export type Property_Avg_Fields = {
  down_payment?: Maybe<Scalars['Float']>;
  pet_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "property" */
export type Property_Avg_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
export type Property_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Bool_Exp>>;
  _not?: InputMaybe<Property_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  bic?: InputMaybe<String_Comparison_Exp>;
  check_in?: InputMaybe<String_Comparison_Exp>;
  check_out?: InputMaybe<String_Comparison_Exp>;
  clients?: InputMaybe<Client_Bool_Exp>;
  clients_aggregate?: InputMaybe<Client_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency_code?: InputMaybe<String_Comparison_Exp>;
  down_payment?: InputMaybe<Float8_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expenses?: InputMaybe<Expense_Bool_Exp>;
  expenses_aggregate?: InputMaybe<Expense_Aggregate_Bool_Exp>;
  iban?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  legal_entity?: InputMaybe<String_Comparison_Exp>;
  legal_entity_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pet_price?: InputMaybe<Float8_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  reservations?: InputMaybe<Reservation_Bool_Exp>;
  reservations_aggregate?: InputMaybe<Reservation_Aggregate_Bool_Exp>;
  room_types?: InputMaybe<Room_Type_Bool_Exp>;
  room_types_aggregate?: InputMaybe<Room_Type_Aggregate_Bool_Exp>;
  rooms?: InputMaybe<Room_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Room_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "property" */
export enum Property_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyPkey = 'property_pkey'
}

/** input type for incrementing numeric columns in table "property" */
export type Property_Inc_Input = {
  down_payment?: InputMaybe<Scalars['float8']>;
  pet_price?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "property" */
export type Property_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  check_in?: InputMaybe<Scalars['String']>;
  check_out?: InputMaybe<Scalars['String']>;
  clients?: InputMaybe<Client_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  down_payment?: InputMaybe<Scalars['float8']>;
  email?: InputMaybe<Scalars['String']>;
  expenses?: InputMaybe<Expense_Arr_Rel_Insert_Input>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  legal_entity?: InputMaybe<Scalars['String']>;
  legal_entity_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pet_price?: InputMaybe<Scalars['float8']>;
  phone_number?: InputMaybe<Scalars['String']>;
  reservations?: InputMaybe<Reservation_Arr_Rel_Insert_Input>;
  room_types?: InputMaybe<Room_Type_Arr_Rel_Insert_Input>;
  rooms?: InputMaybe<Room_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Property_Max_Fields = {
  address?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  check_in?: Maybe<Scalars['String']>;
  check_out?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  down_payment?: Maybe<Scalars['float8']>;
  email?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  legal_entity?: Maybe<Scalars['String']>;
  legal_entity_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pet_price?: Maybe<Scalars['float8']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "property" */
export type Property_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  bic?: InputMaybe<Order_By>;
  check_in?: InputMaybe<Order_By>;
  check_out?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  down_payment?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  iban?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legal_entity?: InputMaybe<Order_By>;
  legal_entity_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Property_Min_Fields = {
  address?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  check_in?: Maybe<Scalars['String']>;
  check_out?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  down_payment?: Maybe<Scalars['float8']>;
  email?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  legal_entity?: Maybe<Scalars['String']>;
  legal_entity_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pet_price?: Maybe<Scalars['float8']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "property" */
export type Property_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  bic?: InputMaybe<Order_By>;
  check_in?: InputMaybe<Order_By>;
  check_out?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  down_payment?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  iban?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legal_entity?: InputMaybe<Order_By>;
  legal_entity_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "property" */
export type Property_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property>;
};

/** input type for inserting object relation for remote table "property" */
export type Property_Obj_Rel_Insert_Input = {
  data: Property_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_On_Conflict>;
};

/** on_conflict condition type for table "property" */
export type Property_On_Conflict = {
  constraint: Property_Constraint;
  update_columns?: Array<Property_Update_Column>;
  where?: InputMaybe<Property_Bool_Exp>;
};

/** Ordering options when selecting data from "property". */
export type Property_Order_By = {
  address?: InputMaybe<Order_By>;
  bic?: InputMaybe<Order_By>;
  check_in?: InputMaybe<Order_By>;
  check_out?: InputMaybe<Order_By>;
  clients_aggregate?: InputMaybe<Client_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  down_payment?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expenses_aggregate?: InputMaybe<Expense_Aggregate_Order_By>;
  iban?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legal_entity?: InputMaybe<Order_By>;
  legal_entity_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  reservations_aggregate?: InputMaybe<Reservation_Aggregate_Order_By>;
  room_types_aggregate?: InputMaybe<Room_Type_Aggregate_Order_By>;
  rooms_aggregate?: InputMaybe<Room_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property */
export type Property_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "property" */
export enum Property_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Bic = 'bic',
  /** column name */
  CheckIn = 'check_in',
  /** column name */
  CheckOut = 'check_out',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  Email = 'email',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  LegalEntity = 'legal_entity',
  /** column name */
  LegalEntityId = 'legal_entity_id',
  /** column name */
  Name = 'name',
  /** column name */
  PetPrice = 'pet_price',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "property_aggregate_bool_exp_avg_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  PetPrice = 'pet_price'
}

/** select "property_aggregate_bool_exp_corr_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  PetPrice = 'pet_price'
}

/** select "property_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  PetPrice = 'pet_price'
}

/** select "property_aggregate_bool_exp_max_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  PetPrice = 'pet_price'
}

/** select "property_aggregate_bool_exp_min_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  PetPrice = 'pet_price'
}

/** select "property_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  PetPrice = 'pet_price'
}

/** select "property_aggregate_bool_exp_sum_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  PetPrice = 'pet_price'
}

/** select "property_aggregate_bool_exp_var_samp_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  PetPrice = 'pet_price'
}

/** input type for updating data in table "property" */
export type Property_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  check_in?: InputMaybe<Scalars['String']>;
  check_out?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  down_payment?: InputMaybe<Scalars['float8']>;
  email?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  legal_entity?: InputMaybe<Scalars['String']>;
  legal_entity_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pet_price?: InputMaybe<Scalars['float8']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Property_Stddev_Fields = {
  down_payment?: Maybe<Scalars['Float']>;
  pet_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "property" */
export type Property_Stddev_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Property_Stddev_Pop_Fields = {
  down_payment?: Maybe<Scalars['Float']>;
  pet_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "property" */
export type Property_Stddev_Pop_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Property_Stddev_Samp_Fields = {
  down_payment?: Maybe<Scalars['Float']>;
  pet_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "property" */
export type Property_Stddev_Samp_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property" */
export type Property_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  check_in?: InputMaybe<Scalars['String']>;
  check_out?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  down_payment?: InputMaybe<Scalars['float8']>;
  email?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  legal_entity?: InputMaybe<Scalars['String']>;
  legal_entity_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pet_price?: InputMaybe<Scalars['float8']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Property_Sum_Fields = {
  down_payment?: Maybe<Scalars['float8']>;
  pet_price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "property" */
export type Property_Sum_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
};

/** update columns of table "property" */
export enum Property_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Bic = 'bic',
  /** column name */
  CheckIn = 'check_in',
  /** column name */
  CheckOut = 'check_out',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  Email = 'email',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  LegalEntity = 'legal_entity',
  /** column name */
  LegalEntityId = 'legal_entity_id',
  /** column name */
  Name = 'name',
  /** column name */
  PetPrice = 'pet_price',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Property_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Property_Var_Pop_Fields = {
  down_payment?: Maybe<Scalars['Float']>;
  pet_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "property" */
export type Property_Var_Pop_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Property_Var_Samp_Fields = {
  down_payment?: Maybe<Scalars['Float']>;
  pet_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "property" */
export type Property_Var_Samp_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Property_Variance_Fields = {
  down_payment?: Maybe<Scalars['Float']>;
  pet_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "property" */
export type Property_Variance_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  pet_price?: InputMaybe<Order_By>;
};

export type Query_Root = {
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "expense" */
  expense: Array<Expense>;
  /** fetch aggregated fields from the table: "expense" */
  expense_aggregate: Expense_Aggregate;
  /** fetch data from the table: "expense" using primary key columns */
  expense_by_pk?: Maybe<Expense>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "property" */
  property: Array<Property>;
  /** fetch aggregated fields from the table: "property" */
  property_aggregate: Property_Aggregate;
  /** fetch data from the table: "property" using primary key columns */
  property_by_pk?: Maybe<Property>;
  /** fetch data from the table: "reservation" */
  reservation: Array<Reservation>;
  /** fetch aggregated fields from the table: "reservation" */
  reservation_aggregate: Reservation_Aggregate;
  /** fetch data from the table: "reservation" using primary key columns */
  reservation_by_pk?: Maybe<Reservation>;
  /** An array relationship */
  reservation_rooms: Array<Reservation_Rooms>;
  /** An aggregate relationship */
  reservation_rooms_aggregate: Reservation_Rooms_Aggregate;
  /** fetch data from the table: "reservation_rooms" using primary key columns */
  reservation_rooms_by_pk?: Maybe<Reservation_Rooms>;
  /** fetch data from the table: "room" */
  room: Array<Room>;
  /** fetch aggregated fields from the table: "room" */
  room_aggregate: Room_Aggregate;
  /** fetch data from the table: "room" using primary key columns */
  room_by_pk?: Maybe<Room>;
  /** fetch data from the table: "room_price" */
  room_price: Array<Room_Price>;
  /** fetch aggregated fields from the table: "room_price" */
  room_price_aggregate: Room_Price_Aggregate;
  /** fetch data from the table: "room_price" using primary key columns */
  room_price_by_pk?: Maybe<Room_Price>;
  /** fetch data from the table: "room_type" */
  room_type: Array<Room_Type>;
  /** fetch aggregated fields from the table: "room_type" */
  room_type_aggregate: Room_Type_Aggregate;
  /** fetch data from the table: "room_type" using primary key columns */
  room_type_by_pk?: Maybe<Room_Type>;
  stripe_price?: Maybe<Array<Maybe<PricesOutput>>>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


export type Query_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Query_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Query_RootClient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExpenseArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Query_RootExpense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Query_RootExpense_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Query_RootProperty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Query_RootProperty_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReservationArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Query_RootReservation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Query_RootReservation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReservation_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Rooms_Order_By>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};


export type Query_RootReservation_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Rooms_Order_By>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};


export type Query_RootReservation_Rooms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRoomArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Query_RootRoom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Query_RootRoom_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRoom_PriceArgs = {
  distinct_on?: InputMaybe<Array<Room_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Price_Order_By>>;
  where?: InputMaybe<Room_Price_Bool_Exp>;
};


export type Query_RootRoom_Price_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Price_Order_By>>;
  where?: InputMaybe<Room_Price_Bool_Exp>;
};


export type Query_RootRoom_Price_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRoom_TypeArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Query_RootRoom_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Query_RootRoom_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStripe_PriceArgs = {
  currency: Scalars['String'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** Represents a reservation */
export type Reservation = {
  arrival_date: Scalars['date'];
  /** An object relationship */
  client: Client;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  departure_date: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  note: Scalars['String'];
  pets: Scalars['Int'];
  /** An object relationship */
  property: Property;
  property_id: Scalars['uuid'];
  /** An array relationship */
  reservation_rooms: Array<Reservation_Rooms>;
  /** An aggregate relationship */
  reservation_rooms_aggregate: Reservation_Rooms_Aggregate;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Represents a reservation */
export type ReservationReservation_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Rooms_Order_By>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};


/** Represents a reservation */
export type ReservationReservation_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Rooms_Order_By>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};

/** aggregated selection of "reservation" */
export type Reservation_Aggregate = {
  aggregate?: Maybe<Reservation_Aggregate_Fields>;
  nodes: Array<Reservation>;
};

export type Reservation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reservation_Aggregate_Bool_Exp_Count>;
};

export type Reservation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reservation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reservation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reservation" */
export type Reservation_Aggregate_Fields = {
  avg?: Maybe<Reservation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reservation_Max_Fields>;
  min?: Maybe<Reservation_Min_Fields>;
  stddev?: Maybe<Reservation_Stddev_Fields>;
  stddev_pop?: Maybe<Reservation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reservation_Stddev_Samp_Fields>;
  sum?: Maybe<Reservation_Sum_Fields>;
  var_pop?: Maybe<Reservation_Var_Pop_Fields>;
  var_samp?: Maybe<Reservation_Var_Samp_Fields>;
  variance?: Maybe<Reservation_Variance_Fields>;
};


/** aggregate fields of "reservation" */
export type Reservation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reservation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservation" */
export type Reservation_Aggregate_Order_By = {
  avg?: InputMaybe<Reservation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reservation_Max_Order_By>;
  min?: InputMaybe<Reservation_Min_Order_By>;
  stddev?: InputMaybe<Reservation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reservation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reservation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reservation_Sum_Order_By>;
  var_pop?: InputMaybe<Reservation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reservation_Var_Samp_Order_By>;
  variance?: InputMaybe<Reservation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reservation" */
export type Reservation_Arr_Rel_Insert_Input = {
  data: Array<Reservation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};

/** aggregate avg on columns */
export type Reservation_Avg_Fields = {
  pets?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reservation" */
export type Reservation_Avg_Order_By = {
  pets?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reservation". All fields are combined with a logical 'AND'. */
export type Reservation_Bool_Exp = {
  _and?: InputMaybe<Array<Reservation_Bool_Exp>>;
  _not?: InputMaybe<Reservation_Bool_Exp>;
  _or?: InputMaybe<Array<Reservation_Bool_Exp>>;
  arrival_date?: InputMaybe<Date_Comparison_Exp>;
  client?: InputMaybe<Client_Bool_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  departure_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  pets?: InputMaybe<Int_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  reservation_rooms?: InputMaybe<Reservation_Rooms_Bool_Exp>;
  reservation_rooms_aggregate?: InputMaybe<Reservation_Rooms_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservation" */
export enum Reservation_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReservationPkey = 'reservation_pkey'
}

/** input type for incrementing numeric columns in table "reservation" */
export type Reservation_Inc_Input = {
  pets?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reservation" */
export type Reservation_Insert_Input = {
  arrival_date?: InputMaybe<Scalars['date']>;
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  departure_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  note?: InputMaybe<Scalars['String']>;
  pets?: InputMaybe<Scalars['Int']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']>;
  reservation_rooms?: InputMaybe<Reservation_Rooms_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reservation_Max_Fields = {
  arrival_date?: Maybe<Scalars['date']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  pets?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reservation" */
export type Reservation_Max_Order_By = {
  arrival_date?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  departure_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  pets?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reservation_Min_Fields = {
  arrival_date?: Maybe<Scalars['date']>;
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  pets?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reservation" */
export type Reservation_Min_Order_By = {
  arrival_date?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  departure_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  pets?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reservation" */
export type Reservation_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation>;
};

/** input type for inserting object relation for remote table "reservation" */
export type Reservation_Obj_Rel_Insert_Input = {
  data: Reservation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};

/** on_conflict condition type for table "reservation" */
export type Reservation_On_Conflict = {
  constraint: Reservation_Constraint;
  update_columns?: Array<Reservation_Update_Column>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};

/** Ordering options when selecting data from "reservation". */
export type Reservation_Order_By = {
  arrival_date?: InputMaybe<Order_By>;
  client?: InputMaybe<Client_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  departure_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  note?: InputMaybe<Order_By>;
  pets?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  reservation_rooms_aggregate?: InputMaybe<Reservation_Rooms_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reservation */
export type Reservation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Represents the rooms of a reservation */
export type Reservation_Rooms = {
  id: Scalars['uuid'];
  /** An object relationship */
  reservation: Reservation;
  reservation_id: Scalars['uuid'];
  /** An object relationship */
  room: Room;
  room_id: Scalars['uuid'];
};

/** aggregated selection of "reservation_rooms" */
export type Reservation_Rooms_Aggregate = {
  aggregate?: Maybe<Reservation_Rooms_Aggregate_Fields>;
  nodes: Array<Reservation_Rooms>;
};

export type Reservation_Rooms_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reservation_Rooms_Aggregate_Bool_Exp_Count>;
};

export type Reservation_Rooms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reservation_Rooms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reservation_rooms" */
export type Reservation_Rooms_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Reservation_Rooms_Max_Fields>;
  min?: Maybe<Reservation_Rooms_Min_Fields>;
};


/** aggregate fields of "reservation_rooms" */
export type Reservation_Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservation_rooms" */
export type Reservation_Rooms_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reservation_Rooms_Max_Order_By>;
  min?: InputMaybe<Reservation_Rooms_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reservation_rooms" */
export type Reservation_Rooms_Arr_Rel_Insert_Input = {
  data: Array<Reservation_Rooms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservation_Rooms_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reservation_rooms". All fields are combined with a logical 'AND'. */
export type Reservation_Rooms_Bool_Exp = {
  _and?: InputMaybe<Array<Reservation_Rooms_Bool_Exp>>;
  _not?: InputMaybe<Reservation_Rooms_Bool_Exp>;
  _or?: InputMaybe<Array<Reservation_Rooms_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reservation?: InputMaybe<Reservation_Bool_Exp>;
  reservation_id?: InputMaybe<Uuid_Comparison_Exp>;
  room?: InputMaybe<Room_Bool_Exp>;
  room_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservation_rooms" */
export enum Reservation_Rooms_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReservationRoomsPkey = 'reservation_rooms_pkey'
}

/** input type for inserting data into table "reservation_rooms" */
export type Reservation_Rooms_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  reservation?: InputMaybe<Reservation_Obj_Rel_Insert_Input>;
  reservation_id?: InputMaybe<Scalars['uuid']>;
  room?: InputMaybe<Room_Obj_Rel_Insert_Input>;
  room_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Reservation_Rooms_Max_Fields = {
  id?: Maybe<Scalars['uuid']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "reservation_rooms" */
export type Reservation_Rooms_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reservation_Rooms_Min_Fields = {
  id?: Maybe<Scalars['uuid']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "reservation_rooms" */
export type Reservation_Rooms_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reservation_rooms" */
export type Reservation_Rooms_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation_Rooms>;
};

/** on_conflict condition type for table "reservation_rooms" */
export type Reservation_Rooms_On_Conflict = {
  constraint: Reservation_Rooms_Constraint;
  update_columns?: Array<Reservation_Rooms_Update_Column>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "reservation_rooms". */
export type Reservation_Rooms_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation?: InputMaybe<Reservation_Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room?: InputMaybe<Room_Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reservation_rooms */
export type Reservation_Rooms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reservation_rooms" */
export enum Reservation_Rooms_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  RoomId = 'room_id'
}

/** input type for updating data in table "reservation_rooms" */
export type Reservation_Rooms_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  reservation_id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "reservation_rooms" */
export type Reservation_Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reservation_Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reservation_Rooms_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  reservation_id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "reservation_rooms" */
export enum Reservation_Rooms_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  RoomId = 'room_id'
}

export type Reservation_Rooms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reservation_Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservation_Rooms_Bool_Exp;
};

/** select columns of table "reservation" */
export enum Reservation_Select_Column {
  /** column name */
  ArrivalDate = 'arrival_date',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartureDate = 'departure_date',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Pets = 'pets',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reservation" */
export type Reservation_Set_Input = {
  arrival_date?: InputMaybe<Scalars['date']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  departure_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  pets?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Reservation_Stddev_Fields = {
  pets?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reservation" */
export type Reservation_Stddev_Order_By = {
  pets?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reservation_Stddev_Pop_Fields = {
  pets?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reservation" */
export type Reservation_Stddev_Pop_Order_By = {
  pets?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reservation_Stddev_Samp_Fields = {
  pets?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reservation" */
export type Reservation_Stddev_Samp_Order_By = {
  pets?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reservation" */
export type Reservation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reservation_Stream_Cursor_Value_Input = {
  arrival_date?: InputMaybe<Scalars['date']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  departure_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  pets?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Reservation_Sum_Fields = {
  pets?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "reservation" */
export type Reservation_Sum_Order_By = {
  pets?: InputMaybe<Order_By>;
};

/** update columns of table "reservation" */
export enum Reservation_Update_Column {
  /** column name */
  ArrivalDate = 'arrival_date',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartureDate = 'departure_date',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Pets = 'pets',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Reservation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reservation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reservation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reservation_Var_Pop_Fields = {
  pets?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reservation" */
export type Reservation_Var_Pop_Order_By = {
  pets?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reservation_Var_Samp_Fields = {
  pets?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reservation" */
export type Reservation_Var_Samp_Order_By = {
  pets?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reservation_Variance_Fields = {
  pets?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reservation" */
export type Reservation_Variance_Order_By = {
  pets?: InputMaybe<Order_By>;
};

/** Represents a room */
export type Room = {
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  property: Property;
  property_id: Scalars['uuid'];
  /** An array relationship */
  reservation_rooms: Array<Reservation_Rooms>;
  /** An aggregate relationship */
  reservation_rooms_aggregate: Reservation_Rooms_Aggregate;
  room_number: Scalars['Int'];
  /** An array relationship */
  room_prices: Array<Room_Price>;
  /** An aggregate relationship */
  room_prices_aggregate: Room_Price_Aggregate;
  /** An object relationship */
  room_type: Room_Type;
  room_type_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** Represents a room */
export type RoomReservation_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Rooms_Order_By>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};


/** Represents a room */
export type RoomReservation_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Rooms_Order_By>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};


/** Represents a room */
export type RoomRoom_PricesArgs = {
  distinct_on?: InputMaybe<Array<Room_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Price_Order_By>>;
  where?: InputMaybe<Room_Price_Bool_Exp>;
};


/** Represents a room */
export type RoomRoom_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Price_Order_By>>;
  where?: InputMaybe<Room_Price_Bool_Exp>;
};

/** aggregated selection of "room" */
export type Room_Aggregate = {
  aggregate?: Maybe<Room_Aggregate_Fields>;
  nodes: Array<Room>;
};

export type Room_Aggregate_Bool_Exp = {
  count?: InputMaybe<Room_Aggregate_Bool_Exp_Count>;
};

export type Room_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "room" */
export type Room_Aggregate_Fields = {
  avg?: Maybe<Room_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Room_Max_Fields>;
  min?: Maybe<Room_Min_Fields>;
  stddev?: Maybe<Room_Stddev_Fields>;
  stddev_pop?: Maybe<Room_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Room_Stddev_Samp_Fields>;
  sum?: Maybe<Room_Sum_Fields>;
  var_pop?: Maybe<Room_Var_Pop_Fields>;
  var_samp?: Maybe<Room_Var_Samp_Fields>;
  variance?: Maybe<Room_Variance_Fields>;
};


/** aggregate fields of "room" */
export type Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room" */
export type Room_Aggregate_Order_By = {
  avg?: InputMaybe<Room_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Max_Order_By>;
  min?: InputMaybe<Room_Min_Order_By>;
  stddev?: InputMaybe<Room_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Room_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Room_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Room_Sum_Order_By>;
  var_pop?: InputMaybe<Room_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Room_Var_Samp_Order_By>;
  variance?: InputMaybe<Room_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "room" */
export type Room_Arr_Rel_Insert_Input = {
  data: Array<Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_On_Conflict>;
};

/** aggregate avg on columns */
export type Room_Avg_Fields = {
  room_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "room" */
export type Room_Avg_Order_By = {
  room_number?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "room". All fields are combined with a logical 'AND'. */
export type Room_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Bool_Exp>>;
  _not?: InputMaybe<Room_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  reservation_rooms?: InputMaybe<Reservation_Rooms_Bool_Exp>;
  reservation_rooms_aggregate?: InputMaybe<Reservation_Rooms_Aggregate_Bool_Exp>;
  room_number?: InputMaybe<Int_Comparison_Exp>;
  room_prices?: InputMaybe<Room_Price_Bool_Exp>;
  room_prices_aggregate?: InputMaybe<Room_Price_Aggregate_Bool_Exp>;
  room_type?: InputMaybe<Room_Type_Bool_Exp>;
  room_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "room" */
export enum Room_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomPkey = 'room_pkey'
}

/** input type for incrementing numeric columns in table "room" */
export type Room_Inc_Input = {
  room_number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "room" */
export type Room_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']>;
  reservation_rooms?: InputMaybe<Reservation_Rooms_Arr_Rel_Insert_Input>;
  room_number?: InputMaybe<Scalars['Int']>;
  room_prices?: InputMaybe<Room_Price_Arr_Rel_Insert_Input>;
  room_type?: InputMaybe<Room_Type_Obj_Rel_Insert_Input>;
  room_type_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Room_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  room_number?: Maybe<Scalars['Int']>;
  room_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "room" */
export type Room_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  room_number?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  room_number?: Maybe<Scalars['Int']>;
  room_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "room" */
export type Room_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  room_number?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room" */
export type Room_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room>;
};

/** input type for inserting object relation for remote table "room" */
export type Room_Obj_Rel_Insert_Input = {
  data: Room_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_On_Conflict>;
};

/** on_conflict condition type for table "room" */
export type Room_On_Conflict = {
  constraint: Room_Constraint;
  update_columns?: Array<Room_Update_Column>;
  where?: InputMaybe<Room_Bool_Exp>;
};

/** Ordering options when selecting data from "room". */
export type Room_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  reservation_rooms_aggregate?: InputMaybe<Reservation_Rooms_Aggregate_Order_By>;
  room_number?: InputMaybe<Order_By>;
  room_prices_aggregate?: InputMaybe<Room_Price_Aggregate_Order_By>;
  room_type?: InputMaybe<Room_Type_Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: room */
export type Room_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Prices of a room */
export type Room_Price = {
  amount: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  currency_code: Scalars['String'];
  date: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  room: Room;
  room_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "room_price" */
export type Room_Price_Aggregate = {
  aggregate?: Maybe<Room_Price_Aggregate_Fields>;
  nodes: Array<Room_Price>;
};

export type Room_Price_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Room_Price_Aggregate_Bool_Exp_Var_Samp>;
};

export type Room_Price_Aggregate_Bool_Exp_Avg = {
  arguments: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Room_Price_Aggregate_Bool_Exp_Corr = {
  arguments: Room_Price_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Room_Price_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Room_Price_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Room_Price_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Room_Price_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Room_Price_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Room_Price_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Room_Price_Aggregate_Bool_Exp_Max = {
  arguments: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Room_Price_Aggregate_Bool_Exp_Min = {
  arguments: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Room_Price_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Room_Price_Aggregate_Bool_Exp_Sum = {
  arguments: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Room_Price_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Price_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "room_price" */
export type Room_Price_Aggregate_Fields = {
  avg?: Maybe<Room_Price_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Room_Price_Max_Fields>;
  min?: Maybe<Room_Price_Min_Fields>;
  stddev?: Maybe<Room_Price_Stddev_Fields>;
  stddev_pop?: Maybe<Room_Price_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Room_Price_Stddev_Samp_Fields>;
  sum?: Maybe<Room_Price_Sum_Fields>;
  var_pop?: Maybe<Room_Price_Var_Pop_Fields>;
  var_samp?: Maybe<Room_Price_Var_Samp_Fields>;
  variance?: Maybe<Room_Price_Variance_Fields>;
};


/** aggregate fields of "room_price" */
export type Room_Price_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Price_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room_price" */
export type Room_Price_Aggregate_Order_By = {
  avg?: InputMaybe<Room_Price_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Price_Max_Order_By>;
  min?: InputMaybe<Room_Price_Min_Order_By>;
  stddev?: InputMaybe<Room_Price_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Room_Price_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Room_Price_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Room_Price_Sum_Order_By>;
  var_pop?: InputMaybe<Room_Price_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Room_Price_Var_Samp_Order_By>;
  variance?: InputMaybe<Room_Price_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "room_price" */
export type Room_Price_Arr_Rel_Insert_Input = {
  data: Array<Room_Price_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Price_On_Conflict>;
};

/** aggregate avg on columns */
export type Room_Price_Avg_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "room_price" */
export type Room_Price_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "room_price". All fields are combined with a logical 'AND'. */
export type Room_Price_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Price_Bool_Exp>>;
  _not?: InputMaybe<Room_Price_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Price_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency_code?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  room?: InputMaybe<Room_Bool_Exp>;
  room_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "room_price" */
export enum Room_Price_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomPricePkey = 'room_price_pkey'
}

/** input type for incrementing numeric columns in table "room_price" */
export type Room_Price_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "room_price" */
export type Room_Price_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  room?: InputMaybe<Room_Obj_Rel_Insert_Input>;
  room_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Room_Price_Max_Fields = {
  amount?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "room_price" */
export type Room_Price_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Price_Min_Fields = {
  amount?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "room_price" */
export type Room_Price_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room_price" */
export type Room_Price_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room_Price>;
};

/** on_conflict condition type for table "room_price" */
export type Room_Price_On_Conflict = {
  constraint: Room_Price_Constraint;
  update_columns?: Array<Room_Price_Update_Column>;
  where?: InputMaybe<Room_Price_Bool_Exp>;
};

/** Ordering options when selecting data from "room_price". */
export type Room_Price_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room?: InputMaybe<Room_Order_By>;
  room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: room_price */
export type Room_Price_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "room_price" */
export enum Room_Price_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "room_price_aggregate_bool_exp_avg_arguments_columns" columns of table "room_price" */
export enum Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "room_price_aggregate_bool_exp_corr_arguments_columns" columns of table "room_price" */
export enum Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "room_price_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "room_price" */
export enum Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "room_price_aggregate_bool_exp_max_arguments_columns" columns of table "room_price" */
export enum Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "room_price_aggregate_bool_exp_min_arguments_columns" columns of table "room_price" */
export enum Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "room_price_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "room_price" */
export enum Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "room_price_aggregate_bool_exp_sum_arguments_columns" columns of table "room_price" */
export enum Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "room_price_aggregate_bool_exp_var_samp_arguments_columns" columns of table "room_price" */
export enum Room_Price_Select_Column_Room_Price_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** input type for updating data in table "room_price" */
export type Room_Price_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Room_Price_Stddev_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "room_price" */
export type Room_Price_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Room_Price_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "room_price" */
export type Room_Price_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Room_Price_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "room_price" */
export type Room_Price_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "room_price" */
export type Room_Price_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Room_Price_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Room_Price_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_code?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Room_Price_Sum_Fields = {
  amount?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "room_price" */
export type Room_Price_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "room_price" */
export enum Room_Price_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Room_Price_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Room_Price_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Room_Price_Set_Input>;
  /** filter the rows which have to be updated */
  where: Room_Price_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Room_Price_Var_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "room_price" */
export type Room_Price_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Room_Price_Var_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "room_price" */
export type Room_Price_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Room_Price_Variance_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "room_price" */
export type Room_Price_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** select columns of table "room" */
export enum Room_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  RoomNumber = 'room_number',
  /** column name */
  RoomTypeId = 'room_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "room" */
export type Room_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  room_number?: InputMaybe<Scalars['Int']>;
  room_type_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Room_Stddev_Fields = {
  room_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "room" */
export type Room_Stddev_Order_By = {
  room_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Room_Stddev_Pop_Fields = {
  room_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "room" */
export type Room_Stddev_Pop_Order_By = {
  room_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Room_Stddev_Samp_Fields = {
  room_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "room" */
export type Room_Stddev_Samp_Order_By = {
  room_number?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "room" */
export type Room_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Room_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Room_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  room_number?: InputMaybe<Scalars['Int']>;
  room_type_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Room_Sum_Fields = {
  room_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "room" */
export type Room_Sum_Order_By = {
  room_number?: InputMaybe<Order_By>;
};

/** Represents a type of a room */
export type Room_Type = {
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  max_occupancy: Scalars['Int'];
  /** An object relationship */
  property: Property;
  property_id: Scalars['uuid'];
  room_type: Scalars['String'];
  /** An array relationship */
  rooms: Array<Room>;
  /** An aggregate relationship */
  rooms_aggregate: Room_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** Represents a type of a room */
export type Room_TypeRoomsArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


/** Represents a type of a room */
export type Room_TypeRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};

/** aggregated selection of "room_type" */
export type Room_Type_Aggregate = {
  aggregate?: Maybe<Room_Type_Aggregate_Fields>;
  nodes: Array<Room_Type>;
};

export type Room_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Room_Type_Aggregate_Bool_Exp_Count>;
};

export type Room_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Room_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "room_type" */
export type Room_Type_Aggregate_Fields = {
  avg?: Maybe<Room_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Room_Type_Max_Fields>;
  min?: Maybe<Room_Type_Min_Fields>;
  stddev?: Maybe<Room_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Room_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Room_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Room_Type_Sum_Fields>;
  var_pop?: Maybe<Room_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Room_Type_Var_Samp_Fields>;
  variance?: Maybe<Room_Type_Variance_Fields>;
};


/** aggregate fields of "room_type" */
export type Room_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room_type" */
export type Room_Type_Aggregate_Order_By = {
  avg?: InputMaybe<Room_Type_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Type_Max_Order_By>;
  min?: InputMaybe<Room_Type_Min_Order_By>;
  stddev?: InputMaybe<Room_Type_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Room_Type_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Room_Type_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Room_Type_Sum_Order_By>;
  var_pop?: InputMaybe<Room_Type_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Room_Type_Var_Samp_Order_By>;
  variance?: InputMaybe<Room_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "room_type" */
export type Room_Type_Arr_Rel_Insert_Input = {
  data: Array<Room_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Room_Type_Avg_Fields = {
  max_occupancy?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "room_type" */
export type Room_Type_Avg_Order_By = {
  max_occupancy?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "room_type". All fields are combined with a logical 'AND'. */
export type Room_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Type_Bool_Exp>>;
  _not?: InputMaybe<Room_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  max_occupancy?: InputMaybe<Int_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  room_type?: InputMaybe<String_Comparison_Exp>;
  rooms?: InputMaybe<Room_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Room_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "room_type" */
export enum Room_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomTypePkey = 'room_type_pkey'
}

/** input type for incrementing numeric columns in table "room_type" */
export type Room_Type_Inc_Input = {
  max_occupancy?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "room_type" */
export type Room_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  max_occupancy?: InputMaybe<Scalars['Int']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']>;
  room_type?: InputMaybe<Scalars['String']>;
  rooms?: InputMaybe<Room_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Room_Type_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  max_occupancy?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['uuid']>;
  room_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "room_type" */
export type Room_Type_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_occupancy?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  room_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Type_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  max_occupancy?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['uuid']>;
  room_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "room_type" */
export type Room_Type_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_occupancy?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  room_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room_type" */
export type Room_Type_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room_Type>;
};

/** input type for inserting object relation for remote table "room_type" */
export type Room_Type_Obj_Rel_Insert_Input = {
  data: Room_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Type_On_Conflict>;
};

/** on_conflict condition type for table "room_type" */
export type Room_Type_On_Conflict = {
  constraint: Room_Type_Constraint;
  update_columns?: Array<Room_Type_Update_Column>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "room_type". */
export type Room_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_occupancy?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  room_type?: InputMaybe<Order_By>;
  rooms_aggregate?: InputMaybe<Room_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: room_type */
export type Room_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "room_type" */
export enum Room_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaxOccupancy = 'max_occupancy',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  RoomType = 'room_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "room_type" */
export type Room_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  max_occupancy?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  room_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Room_Type_Stddev_Fields = {
  max_occupancy?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "room_type" */
export type Room_Type_Stddev_Order_By = {
  max_occupancy?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Room_Type_Stddev_Pop_Fields = {
  max_occupancy?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "room_type" */
export type Room_Type_Stddev_Pop_Order_By = {
  max_occupancy?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Room_Type_Stddev_Samp_Fields = {
  max_occupancy?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "room_type" */
export type Room_Type_Stddev_Samp_Order_By = {
  max_occupancy?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "room_type" */
export type Room_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Room_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Room_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  max_occupancy?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  room_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Room_Type_Sum_Fields = {
  max_occupancy?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "room_type" */
export type Room_Type_Sum_Order_By = {
  max_occupancy?: InputMaybe<Order_By>;
};

/** update columns of table "room_type" */
export enum Room_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaxOccupancy = 'max_occupancy',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  RoomType = 'room_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Room_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Room_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Room_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Room_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Room_Type_Var_Pop_Fields = {
  max_occupancy?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "room_type" */
export type Room_Type_Var_Pop_Order_By = {
  max_occupancy?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Room_Type_Var_Samp_Fields = {
  max_occupancy?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "room_type" */
export type Room_Type_Var_Samp_Order_By = {
  max_occupancy?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Room_Type_Variance_Fields = {
  max_occupancy?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "room_type" */
export type Room_Type_Variance_Order_By = {
  max_occupancy?: InputMaybe<Order_By>;
};

/** update columns of table "room" */
export enum Room_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  RoomNumber = 'room_number',
  /** column name */
  RoomTypeId = 'room_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Room_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Room_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Room_Set_Input>;
  /** filter the rows which have to be updated */
  where: Room_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Room_Var_Pop_Fields = {
  room_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "room" */
export type Room_Var_Pop_Order_By = {
  room_number?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Room_Var_Samp_Fields = {
  room_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "room" */
export type Room_Var_Samp_Order_By = {
  room_number?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Room_Variance_Fields = {
  room_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "room" */
export type Room_Variance_Order_By = {
  room_number?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table in a streaming manner: "client" */
  client_stream: Array<Client>;
  /** fetch data from the table: "expense" */
  expense: Array<Expense>;
  /** fetch aggregated fields from the table: "expense" */
  expense_aggregate: Expense_Aggregate;
  /** fetch data from the table: "expense" using primary key columns */
  expense_by_pk?: Maybe<Expense>;
  /** fetch data from the table in a streaming manner: "expense" */
  expense_stream: Array<Expense>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table in a streaming manner: "invoice" */
  invoice_stream: Array<Invoice>;
  /** fetch data from the table: "property" */
  property: Array<Property>;
  /** fetch aggregated fields from the table: "property" */
  property_aggregate: Property_Aggregate;
  /** fetch data from the table: "property" using primary key columns */
  property_by_pk?: Maybe<Property>;
  /** fetch data from the table in a streaming manner: "property" */
  property_stream: Array<Property>;
  /** fetch data from the table: "reservation" */
  reservation: Array<Reservation>;
  /** fetch aggregated fields from the table: "reservation" */
  reservation_aggregate: Reservation_Aggregate;
  /** fetch data from the table: "reservation" using primary key columns */
  reservation_by_pk?: Maybe<Reservation>;
  /** An array relationship */
  reservation_rooms: Array<Reservation_Rooms>;
  /** An aggregate relationship */
  reservation_rooms_aggregate: Reservation_Rooms_Aggregate;
  /** fetch data from the table: "reservation_rooms" using primary key columns */
  reservation_rooms_by_pk?: Maybe<Reservation_Rooms>;
  /** fetch data from the table in a streaming manner: "reservation_rooms" */
  reservation_rooms_stream: Array<Reservation_Rooms>;
  /** fetch data from the table in a streaming manner: "reservation" */
  reservation_stream: Array<Reservation>;
  /** fetch data from the table: "room" */
  room: Array<Room>;
  /** fetch aggregated fields from the table: "room" */
  room_aggregate: Room_Aggregate;
  /** fetch data from the table: "room" using primary key columns */
  room_by_pk?: Maybe<Room>;
  /** fetch data from the table: "room_price" */
  room_price: Array<Room_Price>;
  /** fetch aggregated fields from the table: "room_price" */
  room_price_aggregate: Room_Price_Aggregate;
  /** fetch data from the table: "room_price" using primary key columns */
  room_price_by_pk?: Maybe<Room_Price>;
  /** fetch data from the table in a streaming manner: "room_price" */
  room_price_stream: Array<Room_Price>;
  /** fetch data from the table in a streaming manner: "room" */
  room_stream: Array<Room>;
  /** fetch data from the table: "room_type" */
  room_type: Array<Room_Type>;
  /** fetch aggregated fields from the table: "room_type" */
  room_type_aggregate: Room_Type_Aggregate;
  /** fetch data from the table: "room_type" using primary key columns */
  room_type_by_pk?: Maybe<Room_Type>;
  /** fetch data from the table in a streaming manner: "room_type" */
  room_type_stream: Array<Room_Type>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
};


export type Subscription_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Subscription_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Subscription_RootClient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Subscription_RootExpenseArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Subscription_RootExpense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Subscription_RootExpense_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExpense_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Expense_Stream_Cursor_Input>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Subscription_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoice_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootPropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Subscription_RootProperty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Subscription_RootProperty_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProperty_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Property_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Subscription_RootReservationArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Subscription_RootReservation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Subscription_RootReservation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReservation_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Rooms_Order_By>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};


export type Subscription_RootReservation_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Rooms_Order_By>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};


export type Subscription_RootReservation_Rooms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReservation_Rooms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reservation_Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<Reservation_Rooms_Bool_Exp>;
};


export type Subscription_RootReservation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reservation_Stream_Cursor_Input>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Subscription_RootRoomArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Subscription_RootRoom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Subscription_RootRoom_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRoom_PriceArgs = {
  distinct_on?: InputMaybe<Array<Room_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Price_Order_By>>;
  where?: InputMaybe<Room_Price_Bool_Exp>;
};


export type Subscription_RootRoom_Price_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Price_Order_By>>;
  where?: InputMaybe<Room_Price_Bool_Exp>;
};


export type Subscription_RootRoom_Price_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRoom_Price_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Room_Price_Stream_Cursor_Input>>;
  where?: InputMaybe<Room_Price_Bool_Exp>;
};


export type Subscription_RootRoom_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Room_Stream_Cursor_Input>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Subscription_RootRoom_TypeArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Subscription_RootRoom_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Subscription_RootRoom_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRoom_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Room_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Represents an paying user of the application */
export type User = {
  has_onboarded: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An array relationship */
  properties: Array<Property>;
  /** An aggregate relationship */
  properties_aggregate: Property_Aggregate;
  selected_locale: Scalars['String'];
  selected_property_id?: Maybe<Scalars['uuid']>;
  stripe_customer_id: Scalars['String'];
  stripe_subscription_id?: Maybe<Scalars['String']>;
};


/** Represents an paying user of the application */
export type UserPropertiesArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


/** Represents an paying user of the application */
export type UserProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  has_onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  properties?: InputMaybe<Property_Bool_Exp>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Bool_Exp>;
  selected_locale?: InputMaybe<String_Comparison_Exp>;
  selected_property_id?: InputMaybe<Uuid_Comparison_Exp>;
  stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  stripe_subscription_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "stripe_customer_id" */
  UserStripeCustomerIdKey = 'user_stripe_customer_id_key'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  has_onboarded?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  properties?: InputMaybe<Property_Arr_Rel_Insert_Input>;
  selected_locale?: InputMaybe<Scalars['String']>;
  selected_property_id?: InputMaybe<Scalars['uuid']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  stripe_subscription_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  id?: Maybe<Scalars['uuid']>;
  selected_locale?: Maybe<Scalars['String']>;
  selected_property_id?: Maybe<Scalars['uuid']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  id?: Maybe<Scalars['uuid']>;
  selected_locale?: Maybe<Scalars['String']>;
  selected_property_id?: Maybe<Scalars['uuid']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  has_onboarded?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Order_By>;
  selected_locale?: InputMaybe<Order_By>;
  selected_property_id?: InputMaybe<Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  stripe_subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  HasOnboarded = 'has_onboarded',
  /** column name */
  Id = 'id',
  /** column name */
  SelectedLocale = 'selected_locale',
  /** column name */
  SelectedPropertyId = 'selected_property_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  has_onboarded?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  selected_locale?: InputMaybe<Scalars['String']>;
  selected_property_id?: InputMaybe<Scalars['uuid']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  stripe_subscription_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  has_onboarded?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  selected_locale?: InputMaybe<Scalars['String']>;
  selected_property_id?: InputMaybe<Scalars['uuid']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  stripe_subscription_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  HasOnboarded = 'has_onboarded',
  /** column name */
  Id = 'id',
  /** column name */
  SelectedLocale = 'selected_locale',
  /** column name */
  SelectedPropertyId = 'selected_property_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type ClientsSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type ClientsSubscription = { client: Array<{ id: any, first_name: string, last_name: string }> };

export type ClientSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type ClientSubscription = { client: Array<{ created_at: any, email?: string | null, phone_number: string, updated_at: any, id: any, first_name: string, last_name: string, reservations_aggregate: { aggregate?: { count: number } | null } }> };

export type Client_BaseFragment = { id: any, first_name: string, last_name: string };

export type CapacityCheckSubscriptionVariables = Exact<{
  arrival_date?: InputMaybe<Scalars['date']>;
  departure_date?: InputMaybe<Scalars['date']>;
  property_id: Scalars['uuid'];
}>;


export type CapacityCheckSubscription = { room: Array<{ id: any, room_number: number, room_type: { room_type: string } }> };

export type RoomNumbersSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type RoomNumbersSubscription = { room: Array<{ id: any, room_number: number, room_type: { room_type: string } }> };

export type RoomNumbersPricesSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type RoomNumbersPricesSubscription = { room: Array<{ id: any, room_number: number, room_type: { room_type: string }, room_prices: Array<{ id: any, amount: any, date: any, currency_code: string }> }> };

export type RoomFragment = { id: any, room_number: number, room_type: { room_type: string } };

export type InsertReservationMutationVariables = Exact<{
  objects: Array<Reservation_Insert_Input> | Reservation_Insert_Input;
}>;


export type InsertReservationMutation = { insert_reservation?: { affected_rows: number } | null };

export type AllReservationsSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type AllReservationsSubscription = { reservation: Array<{ id: any, arrival_date: any, departure_date: any, created_at: any, updated_at: any, pets: number, status: string, client: { id: any, first_name: string, last_name: string }, reservation_rooms: Array<{ room: { id: any, room_number: number, room_type: { room_type: string } } }> }> };

export type RoomTypeSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;


export type RoomTypeSubscription = { room_type: Array<{ id: any, created_at: any, updated_at: any, max_occupancy: number, room_type: string, rooms_aggregate: { aggregate?: { count: number } | null } }> };

export type Room_TypeFragment = { id: any, created_at: any, updated_at: any, max_occupancy: number, room_type: string };

export type Room_Type_Rooms_AggregateFragment = { rooms_aggregate: { aggregate?: { count: number } | null } };

export type RoomSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type RoomSubscription = { room: Array<{ created_at: any, updated_at: any, id: any, room_number: number, room_type: { room_type: string } }> };

export type InsertRoomTypeMutationVariables = Exact<{
  object: Room_Type_Insert_Input;
}>;


export type InsertRoomTypeMutation = { insert_room_type_one?: { id: any } | null };

export type RoomTypesSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type RoomTypesSubscription = { room_type: Array<{ id: any, room_type: string }> };

export type InsertRoomMutationVariables = Exact<{
  object: Room_Insert_Input;
}>;


export type InsertRoomMutation = { insert_room_one?: { id: any } | null };

export type ReservationSchedulesSubscriptionVariables = Exact<{
  reservation_bool_exp: Reservation_Bool_Exp;
}>;


export type ReservationSchedulesSubscription = { reservation: Array<{ id: any, arrival_date: any, departure_date: any, status: string, client: { id: any, first_name: string, last_name: string }, reservation_rooms: Array<{ room: { id: any, room_number: number } }> }> };

export type PropertiesSubscriptionVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type PropertiesSubscription = { property: Array<{ id: any, created_at: any, updated_at: any, name: string, email: string, phone_number: string, address: string, check_in: string, check_out: string, legal_entity?: string | null, legal_entity_id?: string | null, iban?: string | null, bic?: string | null, pet_price?: any | null, down_payment?: any | null }> };

export type InsertPropertyMutationVariables = Exact<{
  object: Property_Insert_Input;
}>;


export type InsertPropertyMutation = { insert_property_one?: { id: any } | null };

export type PropertyCountSubscriptionVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type PropertyCountSubscription = { property_aggregate: { aggregate?: { count: number } | null } };

export type RoomCountSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type RoomCountSubscription = { room_aggregate: { aggregate?: { count: number } | null } };

export type RoomTypeCountSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type RoomTypeCountSubscription = { room_type_aggregate: { aggregate?: { count: number } | null } };

export type InsertUserMutationVariables = Exact<{
  object: User_Insert_Input;
}>;


export type InsertUserMutation = { insert_user_one?: { stripe_customer_id: string, id: any } | null };

export type StripeCustomerIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type StripeCustomerIdSubscription = { user_by_pk?: { id: any, stripe_customer_id: string, stripe_subscription_id?: string | null, has_onboarded: boolean } | null };

export type StripePricesQueryVariables = Exact<{
  currency: Scalars['String'];
}>;


export type StripePricesQuery = { stripe_price?: Array<{ id: string, currency: string, unit_amount: number, recurring: { interval: string } } | null> | null };

export type UpdateUserOnboardedMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UpdateUserOnboardedMutation = { update_user_by_pk?: { has_onboarded: boolean } | null };

export type CurrentReservationsSubscriptionVariables = Exact<{
  _lte: Scalars['date'];
  _gte: Scalars['date'];
  property_id: Scalars['uuid'];
}>;


export type CurrentReservationsSubscription = { reservation: Array<{ id: any, arrival_date: any, departure_date: any, status: string, client: { first_name: string, last_name: string } }> };

export type ArrivingReservationsSubscriptionVariables = Exact<{
  _eq: Scalars['date'];
  property_id: Scalars['uuid'];
}>;


export type ArrivingReservationsSubscription = { reservation: Array<{ id: any, arrival_date: any, departure_date: any, status: string, client: { first_name: string, last_name: string } }> };

export type DepartingReservationsSubscriptionVariables = Exact<{
  _eq: Scalars['date'];
  property_id: Scalars['uuid'];
}>;


export type DepartingReservationsSubscription = { reservation: Array<{ id: any, arrival_date: any, departure_date: any, status: string, client: { first_name: string, last_name: string } }> };

export type PendingPaymentReservationsSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type PendingPaymentReservationsSubscription = { reservation: Array<{ id: any, arrival_date: any, departure_date: any, status: string, pets: number, client: { first_name: string, last_name: string }, reservation_rooms: Array<{ room: { room_type: { room_type: string, max_occupancy: number } } }> }> };

export type PedingFullPaymentReservationsSubscriptionVariables = Exact<{
  property_id: Scalars['uuid'];
}>;


export type PedingFullPaymentReservationsSubscription = { reservation: Array<{ id: any, arrival_date: any, departure_date: any, status: string, pets: number, client: { first_name: string, last_name: string }, reservation_rooms: Array<{ room: { room_type: { room_type: string, max_occupancy: number } } }> }> };

export type UserPropertiesSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserPropertiesSubscription = { user_by_pk?: { selected_property_id?: any | null, selected_locale: string, properties: Array<{ id: any, name: string, currency_code: string }> } | null };

export type PropertyFragment = { id: any, name: string, currency_code: string };

export type UpdateUserSelectedPropertyMutationVariables = Exact<{
  selected_property_id: Scalars['uuid'];
  id: Scalars['uuid'];
}>;


export type UpdateUserSelectedPropertyMutation = { update_user_by_pk?: { id: any } | null };

export type ReservationByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ReservationByIdSubscription = { reservation_by_pk?: { arrival_date: any, departure_date: any, note: string, status: string, pets: number, client: { id: any, first_name: string, last_name: string, phone_number: string, email?: string | null }, invoice?: { id: any } | null, reservation_rooms: Array<{ room: { id: any, room_type: { room_type: string } } }> } | null };

export type UpdateReservationMutationVariables = Exact<{
  id: Scalars['uuid'];
  reservation_set_input: Reservation_Set_Input;
  reservation_rooms_insert_input: Array<Reservation_Rooms_Insert_Input> | Reservation_Rooms_Insert_Input;
  reservation_rooms_bool_exp: Reservation_Rooms_Bool_Exp;
  client_set_input: Client_Set_Input;
  client_id: Scalars['uuid'];
}>;


export type UpdateReservationMutation = { update_reservation_by_pk?: { __typename: 'reservation' } | null, insert_reservation_rooms?: { affected_rows: number } | null, delete_reservation_rooms?: { affected_rows: number } | null, update_client_by_pk?: { id: any } | null };

export type DeleteReservationMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteReservationMutation = { delete_reservation_rooms?: { affected_rows: number } | null, delete_invoice?: { affected_rows: number } | null, delete_reservation?: { affected_rows: number } | null };

export type PropertyExpensesSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PropertyExpensesSubscription = { property_by_pk?: { expenses: Array<{ amount: number, created_at: any, id: any, note?: string | null, property_id: any, type: string, updated_at: any, currency_code: string }> } | null };

export type InsertExpenseMutationVariables = Exact<{
  amount: Scalars['Int'];
  note: Scalars['String'];
  property_id: Scalars['uuid'];
  type: Scalars['String'];
  currency_code: Scalars['String'];
}>;


export type InsertExpenseMutation = { insert_expense_one?: { id: any } | null };

export type ExpenseTypesSubscriptionVariables = Exact<{
  _eq: Scalars['uuid'];
}>;


export type ExpenseTypesSubscription = { expense: Array<{ type: string }> };

export type UpdatePriceMutationVariables = Exact<{
  id: Scalars['uuid'];
  amount: Scalars['float8'];
}>;


export type UpdatePriceMutation = { update_room_price_by_pk?: { id: any } | null };

export type CreatePriceMutationVariables = Exact<{
  amount: Scalars['float8'];
  date: Scalars['date'];
  room_id: Scalars['uuid'];
  currency_code: Scalars['String'];
}>;


export type CreatePriceMutation = { insert_room_price_one?: { id: any } | null };

export type InvoiceSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  arrival_date: Scalars['date'];
  departure_date: Scalars['date'];
}>;


export type InvoiceSubscription = { reservation_by_pk?: { created_at: any, arrival_date: any, departure_date: any, pets: number, client: { first_name: string, last_name: string, email?: string | null, phone_number: string, property_id: any, property: { currency_code: string, user: { selected_locale: string } } }, invoice?: { id: any, number: number, discount?: any | null } | null, reservation_rooms: Array<{ room: { id: any, room_number: number, room_type: { room_type: string, max_occupancy: number }, room_prices: Array<{ id: any, room_id: any, amount: any, date: any }> } }> } | null };

export type DeletePriceMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePriceMutation = { delete_room_price_by_pk?: { id: any } | null };

export type PropertyStatsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PropertyStatsSubscription = { property_by_pk?: { name: string, email: string, phone_number: string, address: string, check_in: string, check_out: string, iban?: string | null, bic?: string | null, legal_entity?: string | null, legal_entity_id?: string | null, pet_price?: any | null, down_payment?: any | null, currency_code: string } | null };

export type UpdatePropertyMutationVariables = Exact<{
  id: Scalars['uuid'];
  address: Scalars['String'];
  bic: Scalars['String'];
  check_in: Scalars['String'];
  check_out: Scalars['String'];
  email: Scalars['String'];
  iban: Scalars['String'];
  legal_entity: Scalars['String'];
  legal_entity_id: Scalars['String'];
  name: Scalars['String'];
  phone_number: Scalars['String'];
  pet_price?: InputMaybe<Scalars['float8']>;
  down_payment?: InputMaybe<Scalars['float8']>;
  currency_code: Scalars['String'];
}>;


export type UpdatePropertyMutation = { update_property_by_pk?: { id: any } | null };

export type DeletePropertyMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePropertyMutation = { delete_property_by_pk?: { id: any } | null };

export type UpdateInvoiceMutationVariables = Exact<{
  id: Scalars['uuid'];
  discount?: InputMaybe<Scalars['float8']>;
}>;


export type UpdateInvoiceMutation = { update_invoice_by_pk?: { id: any } | null };

export type GetInvoiceByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetInvoiceByIdSubscription = { invoice_by_pk?: { reservation?: { id: any, arrival_date: any, departure_date: any, property: { user: { selected_locale: string, selected_property_id?: any | null } } } | null } | null };

export type SetSelectedLolcaleMutationVariables = Exact<{
  id: Scalars['uuid'];
  selected_locale: Scalars['String'];
}>;


export type SetSelectedLolcaleMutation = { update_user_by_pk?: { id: any } | null };

export type GetRoomTypeSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetRoomTypeSubscription = { room_type_by_pk?: { room_type: string, max_occupancy: number } | null };

export type UpdateRoomTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Room_Type_Set_Input;
}>;


export type UpdateRoomTypeMutation = { update_room_type_by_pk?: { id: any } | null };

export type GetRoomByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetRoomByIdSubscription = { room_by_pk?: { id: any, room_number: number, room_type_id: any } | null };

export type UpdateRoomByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Room_Set_Input;
}>;


export type UpdateRoomByIdMutation = { update_room_by_pk?: { id: any } | null };

export type DeleteRoomByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteRoomByIdMutation = { delete_room?: { affected_rows: number } | null };

export type DeleteRoomTypeByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteRoomTypeByIdMutation = { delete_room_type?: { affected_rows: number } | null };

export type GetClientByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetClientByIdSubscription = { client_by_pk?: { email?: string | null, first_name: string, last_name: string, phone_number: string } | null };

export type Update_Client_By_Pk_FragmentFragment = { update_client_by_pk?: { id: any } | null };

export type UpdateClientByIdMutationVariables = Exact<{
  client_set_input: Client_Set_Input;
  client_id: Scalars['uuid'];
}>;


export type UpdateClientByIdMutation = { update_client_by_pk?: { id: any } | null };

export type DeleteClientByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteClientByIdMutation = { delete_client_by_pk?: { id: any } | null };

export type CreateClientMutationVariables = Exact<{
  object: Client_Insert_Input;
}>;


export type CreateClientMutation = { insert_client_one?: { id: any } | null };

export type RoomDataSubscriptionVariables = Exact<{
  where?: InputMaybe<Room_Bool_Exp>;
  where1?: InputMaybe<Room_Price_Bool_Exp>;
}>;


export type RoomDataSubscription = { room: Array<{ id: any, room_prices: Array<{ date: any, amount: any }> }> };

export const Client_BaseFragmentDoc = gql`
    fragment client_base on client {
  id
  first_name
  last_name
}
    `;
export const RoomFragmentDoc = gql`
    fragment room on room {
  id
  room_number
  room_type {
    room_type
  }
}
    `;
export const Room_TypeFragmentDoc = gql`
    fragment room_type on room_type {
  id
  created_at
  updated_at
  max_occupancy
  room_type
}
    `;
export const Room_Type_Rooms_AggregateFragmentDoc = gql`
    fragment room_type_rooms_aggregate on room_type {
  rooms_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const PropertyFragmentDoc = gql`
    fragment Property on property {
  id
  name
  currency_code
}
    `;
export const Update_Client_By_Pk_FragmentFragmentDoc = gql`
    fragment update_client_by_pk_fragment on mutation_root {
  update_client_by_pk(_set: $client_set_input, pk_columns: {id: $client_id}) {
    id
  }
}
    `;
export const ClientsDocument = gql`
    subscription Clients($property_id: uuid!) {
  client(where: {property_id: {_eq: $property_id}}, order_by: {first_name: asc}) {
    ...client_base
  }
}
    ${Client_BaseFragmentDoc}`;

/**
 * __useClientsSubscription__
 *
 * To run a query within a React component, call `useClientsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useClientsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ClientsSubscription, ClientsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ClientsSubscription, ClientsSubscriptionVariables>(ClientsDocument, options);
      }
export type ClientsSubscriptionHookResult = ReturnType<typeof useClientsSubscription>;
export type ClientsSubscriptionResult = Apollo.SubscriptionResult<ClientsSubscription>;
export const ClientDocument = gql`
    subscription Client($property_id: uuid!) {
  client(where: {property_id: {_eq: $property_id}}) {
    ...client_base
    created_at
    email
    phone_number
    updated_at
    reservations_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${Client_BaseFragmentDoc}`;

/**
 * __useClientSubscription__
 *
 * To run a query within a React component, call `useClientSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useClientSubscription(baseOptions: Apollo.SubscriptionHookOptions<ClientSubscription, ClientSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ClientSubscription, ClientSubscriptionVariables>(ClientDocument, options);
      }
export type ClientSubscriptionHookResult = ReturnType<typeof useClientSubscription>;
export type ClientSubscriptionResult = Apollo.SubscriptionResult<ClientSubscription>;
export const CapacityCheckDocument = gql`
    subscription CapacityCheck($arrival_date: date, $departure_date: date, $property_id: uuid!) {
  room(
    where: {property_id: {_eq: $property_id}, _not: {reservation_rooms: {reservation: {arrival_date: {_lte: $departure_date}, departure_date: {_gte: $arrival_date}}}}}
  ) {
    ...room
  }
}
    ${RoomFragmentDoc}`;

/**
 * __useCapacityCheckSubscription__
 *
 * To run a query within a React component, call `useCapacityCheckSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCapacityCheckSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapacityCheckSubscription({
 *   variables: {
 *      arrival_date: // value for 'arrival_date'
 *      departure_date: // value for 'departure_date'
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useCapacityCheckSubscription(baseOptions: Apollo.SubscriptionHookOptions<CapacityCheckSubscription, CapacityCheckSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CapacityCheckSubscription, CapacityCheckSubscriptionVariables>(CapacityCheckDocument, options);
      }
export type CapacityCheckSubscriptionHookResult = ReturnType<typeof useCapacityCheckSubscription>;
export type CapacityCheckSubscriptionResult = Apollo.SubscriptionResult<CapacityCheckSubscription>;
export const RoomNumbersDocument = gql`
    subscription RoomNumbers($property_id: uuid!) {
  room(where: {property_id: {_eq: $property_id}}) {
    ...room
  }
}
    ${RoomFragmentDoc}`;

/**
 * __useRoomNumbersSubscription__
 *
 * To run a query within a React component, call `useRoomNumbersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomNumbersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomNumbersSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useRoomNumbersSubscription(baseOptions: Apollo.SubscriptionHookOptions<RoomNumbersSubscription, RoomNumbersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RoomNumbersSubscription, RoomNumbersSubscriptionVariables>(RoomNumbersDocument, options);
      }
export type RoomNumbersSubscriptionHookResult = ReturnType<typeof useRoomNumbersSubscription>;
export type RoomNumbersSubscriptionResult = Apollo.SubscriptionResult<RoomNumbersSubscription>;
export const RoomNumbersPricesDocument = gql`
    subscription RoomNumbersPrices($property_id: uuid!) {
  room(where: {property_id: {_eq: $property_id}}) {
    id
    room_number
    room_type {
      room_type
    }
    room_prices {
      id
      amount
      date
      currency_code
    }
  }
}
    `;

/**
 * __useRoomNumbersPricesSubscription__
 *
 * To run a query within a React component, call `useRoomNumbersPricesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomNumbersPricesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomNumbersPricesSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useRoomNumbersPricesSubscription(baseOptions: Apollo.SubscriptionHookOptions<RoomNumbersPricesSubscription, RoomNumbersPricesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RoomNumbersPricesSubscription, RoomNumbersPricesSubscriptionVariables>(RoomNumbersPricesDocument, options);
      }
export type RoomNumbersPricesSubscriptionHookResult = ReturnType<typeof useRoomNumbersPricesSubscription>;
export type RoomNumbersPricesSubscriptionResult = Apollo.SubscriptionResult<RoomNumbersPricesSubscription>;
export const InsertReservationDocument = gql`
    mutation InsertReservation($objects: [reservation_insert_input!]!) {
  insert_reservation(objects: $objects) {
    affected_rows
  }
}
    `;
export type InsertReservationMutationFn = Apollo.MutationFunction<InsertReservationMutation, InsertReservationMutationVariables>;

/**
 * __useInsertReservationMutation__
 *
 * To run a mutation, you first call `useInsertReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReservationMutation, { data, loading, error }] = useInsertReservationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertReservationMutation(baseOptions?: Apollo.MutationHookOptions<InsertReservationMutation, InsertReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReservationMutation, InsertReservationMutationVariables>(InsertReservationDocument, options);
      }
export type InsertReservationMutationHookResult = ReturnType<typeof useInsertReservationMutation>;
export type InsertReservationMutationResult = Apollo.MutationResult<InsertReservationMutation>;
export type InsertReservationMutationOptions = Apollo.BaseMutationOptions<InsertReservationMutation, InsertReservationMutationVariables>;
export const AllReservationsDocument = gql`
    subscription AllReservations($property_id: uuid!) {
  reservation(
    order_by: {created_at: desc}
    where: {property_id: {_eq: $property_id}}
  ) {
    id
    arrival_date
    departure_date
    created_at
    updated_at
    pets
    status
    client {
      ...client_base
    }
    reservation_rooms {
      room {
        ...room
      }
    }
  }
}
    ${Client_BaseFragmentDoc}
${RoomFragmentDoc}`;

/**
 * __useAllReservationsSubscription__
 *
 * To run a query within a React component, call `useAllReservationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllReservationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReservationsSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useAllReservationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<AllReservationsSubscription, AllReservationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AllReservationsSubscription, AllReservationsSubscriptionVariables>(AllReservationsDocument, options);
      }
export type AllReservationsSubscriptionHookResult = ReturnType<typeof useAllReservationsSubscription>;
export type AllReservationsSubscriptionResult = Apollo.SubscriptionResult<AllReservationsSubscription>;
export const RoomTypeDocument = gql`
    subscription RoomType($property_id: uuid!, $user_id: uuid!) {
  room_type(
    where: {property_id: {_eq: $property_id}, property: {user_id: {_eq: $user_id}}}
  ) {
    ...room_type
    ...room_type_rooms_aggregate
  }
}
    ${Room_TypeFragmentDoc}
${Room_Type_Rooms_AggregateFragmentDoc}`;

/**
 * __useRoomTypeSubscription__
 *
 * To run a query within a React component, call `useRoomTypeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomTypeSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useRoomTypeSubscription(baseOptions: Apollo.SubscriptionHookOptions<RoomTypeSubscription, RoomTypeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RoomTypeSubscription, RoomTypeSubscriptionVariables>(RoomTypeDocument, options);
      }
export type RoomTypeSubscriptionHookResult = ReturnType<typeof useRoomTypeSubscription>;
export type RoomTypeSubscriptionResult = Apollo.SubscriptionResult<RoomTypeSubscription>;
export const RoomDocument = gql`
    subscription Room($property_id: uuid!) {
  room(where: {property_id: {_eq: $property_id}}) {
    ...room
    created_at
    updated_at
  }
}
    ${RoomFragmentDoc}`;

/**
 * __useRoomSubscription__
 *
 * To run a query within a React component, call `useRoomSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useRoomSubscription(baseOptions: Apollo.SubscriptionHookOptions<RoomSubscription, RoomSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RoomSubscription, RoomSubscriptionVariables>(RoomDocument, options);
      }
export type RoomSubscriptionHookResult = ReturnType<typeof useRoomSubscription>;
export type RoomSubscriptionResult = Apollo.SubscriptionResult<RoomSubscription>;
export const InsertRoomTypeDocument = gql`
    mutation InsertRoomType($object: room_type_insert_input!) {
  insert_room_type_one(object: $object) {
    id
  }
}
    `;
export type InsertRoomTypeMutationFn = Apollo.MutationFunction<InsertRoomTypeMutation, InsertRoomTypeMutationVariables>;

/**
 * __useInsertRoomTypeMutation__
 *
 * To run a mutation, you first call `useInsertRoomTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRoomTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRoomTypeMutation, { data, loading, error }] = useInsertRoomTypeMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertRoomTypeMutation(baseOptions?: Apollo.MutationHookOptions<InsertRoomTypeMutation, InsertRoomTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRoomTypeMutation, InsertRoomTypeMutationVariables>(InsertRoomTypeDocument, options);
      }
export type InsertRoomTypeMutationHookResult = ReturnType<typeof useInsertRoomTypeMutation>;
export type InsertRoomTypeMutationResult = Apollo.MutationResult<InsertRoomTypeMutation>;
export type InsertRoomTypeMutationOptions = Apollo.BaseMutationOptions<InsertRoomTypeMutation, InsertRoomTypeMutationVariables>;
export const RoomTypesDocument = gql`
    subscription RoomTypes($property_id: uuid!) {
  room_type(where: {property_id: {_eq: $property_id}}) {
    id
    room_type
  }
}
    `;

/**
 * __useRoomTypesSubscription__
 *
 * To run a query within a React component, call `useRoomTypesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomTypesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomTypesSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useRoomTypesSubscription(baseOptions: Apollo.SubscriptionHookOptions<RoomTypesSubscription, RoomTypesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RoomTypesSubscription, RoomTypesSubscriptionVariables>(RoomTypesDocument, options);
      }
export type RoomTypesSubscriptionHookResult = ReturnType<typeof useRoomTypesSubscription>;
export type RoomTypesSubscriptionResult = Apollo.SubscriptionResult<RoomTypesSubscription>;
export const InsertRoomDocument = gql`
    mutation InsertRoom($object: room_insert_input!) {
  insert_room_one(object: $object) {
    id
  }
}
    `;
export type InsertRoomMutationFn = Apollo.MutationFunction<InsertRoomMutation, InsertRoomMutationVariables>;

/**
 * __useInsertRoomMutation__
 *
 * To run a mutation, you first call `useInsertRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRoomMutation, { data, loading, error }] = useInsertRoomMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertRoomMutation(baseOptions?: Apollo.MutationHookOptions<InsertRoomMutation, InsertRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRoomMutation, InsertRoomMutationVariables>(InsertRoomDocument, options);
      }
export type InsertRoomMutationHookResult = ReturnType<typeof useInsertRoomMutation>;
export type InsertRoomMutationResult = Apollo.MutationResult<InsertRoomMutation>;
export type InsertRoomMutationOptions = Apollo.BaseMutationOptions<InsertRoomMutation, InsertRoomMutationVariables>;
export const ReservationSchedulesDocument = gql`
    subscription ReservationSchedules($reservation_bool_exp: reservation_bool_exp!) {
  reservation(where: $reservation_bool_exp) {
    id
    arrival_date
    departure_date
    status
    client {
      id
      first_name
      last_name
    }
    reservation_rooms(order_by: {room: {room_number: asc}}) {
      room {
        id
        room_number
      }
    }
  }
}
    `;

/**
 * __useReservationSchedulesSubscription__
 *
 * To run a query within a React component, call `useReservationSchedulesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReservationSchedulesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationSchedulesSubscription({
 *   variables: {
 *      reservation_bool_exp: // value for 'reservation_bool_exp'
 *   },
 * });
 */
export function useReservationSchedulesSubscription(baseOptions: Apollo.SubscriptionHookOptions<ReservationSchedulesSubscription, ReservationSchedulesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ReservationSchedulesSubscription, ReservationSchedulesSubscriptionVariables>(ReservationSchedulesDocument, options);
      }
export type ReservationSchedulesSubscriptionHookResult = ReturnType<typeof useReservationSchedulesSubscription>;
export type ReservationSchedulesSubscriptionResult = Apollo.SubscriptionResult<ReservationSchedulesSubscription>;
export const PropertiesDocument = gql`
    subscription Properties($user_id: uuid!) {
  property(where: {user_id: {_eq: $user_id}}) {
    id
    created_at
    updated_at
    name
    email
    phone_number
    address
    check_in
    check_out
    legal_entity
    legal_entity_id
    iban
    bic
    pet_price
    down_payment
  }
}
    `;

/**
 * __usePropertiesSubscription__
 *
 * To run a query within a React component, call `usePropertiesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesSubscription({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function usePropertiesSubscription(baseOptions: Apollo.SubscriptionHookOptions<PropertiesSubscription, PropertiesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PropertiesSubscription, PropertiesSubscriptionVariables>(PropertiesDocument, options);
      }
export type PropertiesSubscriptionHookResult = ReturnType<typeof usePropertiesSubscription>;
export type PropertiesSubscriptionResult = Apollo.SubscriptionResult<PropertiesSubscription>;
export const InsertPropertyDocument = gql`
    mutation InsertProperty($object: property_insert_input!) {
  insert_property_one(object: $object) {
    id
  }
}
    `;
export type InsertPropertyMutationFn = Apollo.MutationFunction<InsertPropertyMutation, InsertPropertyMutationVariables>;

/**
 * __useInsertPropertyMutation__
 *
 * To run a mutation, you first call `useInsertPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPropertyMutation, { data, loading, error }] = useInsertPropertyMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPropertyMutation(baseOptions?: Apollo.MutationHookOptions<InsertPropertyMutation, InsertPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPropertyMutation, InsertPropertyMutationVariables>(InsertPropertyDocument, options);
      }
export type InsertPropertyMutationHookResult = ReturnType<typeof useInsertPropertyMutation>;
export type InsertPropertyMutationResult = Apollo.MutationResult<InsertPropertyMutation>;
export type InsertPropertyMutationOptions = Apollo.BaseMutationOptions<InsertPropertyMutation, InsertPropertyMutationVariables>;
export const PropertyCountDocument = gql`
    subscription PropertyCount($user_id: uuid!) {
  property_aggregate(where: {user_id: {_eq: $user_id}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePropertyCountSubscription__
 *
 * To run a query within a React component, call `usePropertyCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePropertyCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyCountSubscription({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function usePropertyCountSubscription(baseOptions: Apollo.SubscriptionHookOptions<PropertyCountSubscription, PropertyCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PropertyCountSubscription, PropertyCountSubscriptionVariables>(PropertyCountDocument, options);
      }
export type PropertyCountSubscriptionHookResult = ReturnType<typeof usePropertyCountSubscription>;
export type PropertyCountSubscriptionResult = Apollo.SubscriptionResult<PropertyCountSubscription>;
export const RoomCountDocument = gql`
    subscription RoomCount($property_id: uuid!) {
  room_aggregate(where: {property_id: {_eq: $property_id}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useRoomCountSubscription__
 *
 * To run a query within a React component, call `useRoomCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomCountSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useRoomCountSubscription(baseOptions: Apollo.SubscriptionHookOptions<RoomCountSubscription, RoomCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RoomCountSubscription, RoomCountSubscriptionVariables>(RoomCountDocument, options);
      }
export type RoomCountSubscriptionHookResult = ReturnType<typeof useRoomCountSubscription>;
export type RoomCountSubscriptionResult = Apollo.SubscriptionResult<RoomCountSubscription>;
export const RoomTypeCountDocument = gql`
    subscription RoomTypeCount($property_id: uuid!) {
  room_type_aggregate(where: {property_id: {_eq: $property_id}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useRoomTypeCountSubscription__
 *
 * To run a query within a React component, call `useRoomTypeCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomTypeCountSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useRoomTypeCountSubscription(baseOptions: Apollo.SubscriptionHookOptions<RoomTypeCountSubscription, RoomTypeCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RoomTypeCountSubscription, RoomTypeCountSubscriptionVariables>(RoomTypeCountDocument, options);
      }
export type RoomTypeCountSubscriptionHookResult = ReturnType<typeof useRoomTypeCountSubscription>;
export type RoomTypeCountSubscriptionResult = Apollo.SubscriptionResult<RoomTypeCountSubscription>;
export const InsertUserDocument = gql`
    mutation InsertUser($object: user_insert_input!) {
  insert_user_one(object: $object) {
    stripe_customer_id
    id
  }
}
    `;
export type InsertUserMutationFn = Apollo.MutationFunction<InsertUserMutation, InsertUserMutationVariables>;

/**
 * __useInsertUserMutation__
 *
 * To run a mutation, you first call `useInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsertUserMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertUserMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUserMutation, InsertUserMutationVariables>(InsertUserDocument, options);
      }
export type InsertUserMutationHookResult = ReturnType<typeof useInsertUserMutation>;
export type InsertUserMutationResult = Apollo.MutationResult<InsertUserMutation>;
export type InsertUserMutationOptions = Apollo.BaseMutationOptions<InsertUserMutation, InsertUserMutationVariables>;
export const StripeCustomerIdDocument = gql`
    subscription StripeCustomerId($id: uuid!) {
  user_by_pk(id: $id) {
    id
    stripe_customer_id
    stripe_subscription_id
    has_onboarded
  }
}
    `;

/**
 * __useStripeCustomerIdSubscription__
 *
 * To run a query within a React component, call `useStripeCustomerIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStripeCustomerIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeCustomerIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStripeCustomerIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<StripeCustomerIdSubscription, StripeCustomerIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StripeCustomerIdSubscription, StripeCustomerIdSubscriptionVariables>(StripeCustomerIdDocument, options);
      }
export type StripeCustomerIdSubscriptionHookResult = ReturnType<typeof useStripeCustomerIdSubscription>;
export type StripeCustomerIdSubscriptionResult = Apollo.SubscriptionResult<StripeCustomerIdSubscription>;
export const StripePricesDocument = gql`
    query StripePrices($currency: String!) {
  stripe_price(currency: $currency) {
    id
    currency
    recurring {
      interval
    }
    unit_amount
  }
}
    `;

/**
 * __useStripePricesQuery__
 *
 * To run a query within a React component, call `useStripePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePricesQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useStripePricesQuery(baseOptions: Apollo.QueryHookOptions<StripePricesQuery, StripePricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripePricesQuery, StripePricesQueryVariables>(StripePricesDocument, options);
      }
export function useStripePricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripePricesQuery, StripePricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripePricesQuery, StripePricesQueryVariables>(StripePricesDocument, options);
        }
export type StripePricesQueryHookResult = ReturnType<typeof useStripePricesQuery>;
export type StripePricesLazyQueryHookResult = ReturnType<typeof useStripePricesLazyQuery>;
export type StripePricesQueryResult = Apollo.QueryResult<StripePricesQuery, StripePricesQueryVariables>;
export const UpdateUserOnboardedDocument = gql`
    mutation UpdateUserOnboarded($id: uuid!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {has_onboarded: true}) {
    has_onboarded
  }
}
    `;
export type UpdateUserOnboardedMutationFn = Apollo.MutationFunction<UpdateUserOnboardedMutation, UpdateUserOnboardedMutationVariables>;

/**
 * __useUpdateUserOnboardedMutation__
 *
 * To run a mutation, you first call `useUpdateUserOnboardedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOnboardedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOnboardedMutation, { data, loading, error }] = useUpdateUserOnboardedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserOnboardedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOnboardedMutation, UpdateUserOnboardedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserOnboardedMutation, UpdateUserOnboardedMutationVariables>(UpdateUserOnboardedDocument, options);
      }
export type UpdateUserOnboardedMutationHookResult = ReturnType<typeof useUpdateUserOnboardedMutation>;
export type UpdateUserOnboardedMutationResult = Apollo.MutationResult<UpdateUserOnboardedMutation>;
export type UpdateUserOnboardedMutationOptions = Apollo.BaseMutationOptions<UpdateUserOnboardedMutation, UpdateUserOnboardedMutationVariables>;
export const CurrentReservationsDocument = gql`
    subscription CurrentReservations($_lte: date!, $_gte: date!, $property_id: uuid!) {
  reservation(
    where: {arrival_date: {_lte: $_lte}, departure_date: {_gte: $_gte}, property_id: {_eq: $property_id}}
  ) {
    id
    arrival_date
    departure_date
    status
    client {
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useCurrentReservationsSubscription__
 *
 * To run a query within a React component, call `useCurrentReservationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentReservationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentReservationsSubscription({
 *   variables: {
 *      _lte: // value for '_lte'
 *      _gte: // value for '_gte'
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useCurrentReservationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<CurrentReservationsSubscription, CurrentReservationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CurrentReservationsSubscription, CurrentReservationsSubscriptionVariables>(CurrentReservationsDocument, options);
      }
export type CurrentReservationsSubscriptionHookResult = ReturnType<typeof useCurrentReservationsSubscription>;
export type CurrentReservationsSubscriptionResult = Apollo.SubscriptionResult<CurrentReservationsSubscription>;
export const ArrivingReservationsDocument = gql`
    subscription ArrivingReservations($_eq: date!, $property_id: uuid!) {
  reservation(
    where: {arrival_date: {_eq: $_eq}, property_id: {_eq: $property_id}}
  ) {
    id
    arrival_date
    departure_date
    status
    client {
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useArrivingReservationsSubscription__
 *
 * To run a query within a React component, call `useArrivingReservationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useArrivingReservationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArrivingReservationsSubscription({
 *   variables: {
 *      _eq: // value for '_eq'
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useArrivingReservationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ArrivingReservationsSubscription, ArrivingReservationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ArrivingReservationsSubscription, ArrivingReservationsSubscriptionVariables>(ArrivingReservationsDocument, options);
      }
export type ArrivingReservationsSubscriptionHookResult = ReturnType<typeof useArrivingReservationsSubscription>;
export type ArrivingReservationsSubscriptionResult = Apollo.SubscriptionResult<ArrivingReservationsSubscription>;
export const DepartingReservationsDocument = gql`
    subscription DepartingReservations($_eq: date!, $property_id: uuid!) {
  reservation(
    where: {departure_date: {_eq: $_eq}, property_id: {_eq: $property_id}}
  ) {
    id
    arrival_date
    departure_date
    status
    client {
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useDepartingReservationsSubscription__
 *
 * To run a query within a React component, call `useDepartingReservationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDepartingReservationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartingReservationsSubscription({
 *   variables: {
 *      _eq: // value for '_eq'
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useDepartingReservationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<DepartingReservationsSubscription, DepartingReservationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DepartingReservationsSubscription, DepartingReservationsSubscriptionVariables>(DepartingReservationsDocument, options);
      }
export type DepartingReservationsSubscriptionHookResult = ReturnType<typeof useDepartingReservationsSubscription>;
export type DepartingReservationsSubscriptionResult = Apollo.SubscriptionResult<DepartingReservationsSubscription>;
export const PendingPaymentReservationsDocument = gql`
    subscription PendingPaymentReservations($property_id: uuid!) {
  reservation(
    where: {property_id: {_eq: $property_id}, _or: {status: {_eq: "CONFIRMED"}}}
  ) {
    id
    arrival_date
    departure_date
    status
    pets
    client {
      first_name
      last_name
    }
    reservation_rooms {
      room {
        room_type {
          room_type
          max_occupancy
        }
      }
    }
  }
}
    `;

/**
 * __usePendingPaymentReservationsSubscription__
 *
 * To run a query within a React component, call `usePendingPaymentReservationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePendingPaymentReservationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingPaymentReservationsSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function usePendingPaymentReservationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<PendingPaymentReservationsSubscription, PendingPaymentReservationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PendingPaymentReservationsSubscription, PendingPaymentReservationsSubscriptionVariables>(PendingPaymentReservationsDocument, options);
      }
export type PendingPaymentReservationsSubscriptionHookResult = ReturnType<typeof usePendingPaymentReservationsSubscription>;
export type PendingPaymentReservationsSubscriptionResult = Apollo.SubscriptionResult<PendingPaymentReservationsSubscription>;
export const PedingFullPaymentReservationsDocument = gql`
    subscription PedingFullPaymentReservations($property_id: uuid!) {
  reservation(
    where: {property_id: {_eq: $property_id}, _or: {status: {_eq: "DEPOSIT"}}}
  ) {
    id
    arrival_date
    departure_date
    status
    pets
    client {
      first_name
      last_name
    }
    reservation_rooms {
      room {
        room_type {
          room_type
          max_occupancy
        }
      }
    }
  }
}
    `;

/**
 * __usePedingFullPaymentReservationsSubscription__
 *
 * To run a query within a React component, call `usePedingFullPaymentReservationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePedingFullPaymentReservationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePedingFullPaymentReservationsSubscription({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function usePedingFullPaymentReservationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<PedingFullPaymentReservationsSubscription, PedingFullPaymentReservationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PedingFullPaymentReservationsSubscription, PedingFullPaymentReservationsSubscriptionVariables>(PedingFullPaymentReservationsDocument, options);
      }
export type PedingFullPaymentReservationsSubscriptionHookResult = ReturnType<typeof usePedingFullPaymentReservationsSubscription>;
export type PedingFullPaymentReservationsSubscriptionResult = Apollo.SubscriptionResult<PedingFullPaymentReservationsSubscription>;
export const UserPropertiesDocument = gql`
    subscription UserProperties($id: uuid!) {
  user_by_pk(id: $id) {
    selected_property_id
    selected_locale
    properties {
      ...Property
    }
  }
}
    ${PropertyFragmentDoc}`;

/**
 * __useUserPropertiesSubscription__
 *
 * To run a query within a React component, call `useUserPropertiesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserPropertiesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPropertiesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPropertiesSubscription(baseOptions: Apollo.SubscriptionHookOptions<UserPropertiesSubscription, UserPropertiesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserPropertiesSubscription, UserPropertiesSubscriptionVariables>(UserPropertiesDocument, options);
      }
export type UserPropertiesSubscriptionHookResult = ReturnType<typeof useUserPropertiesSubscription>;
export type UserPropertiesSubscriptionResult = Apollo.SubscriptionResult<UserPropertiesSubscription>;
export const UpdateUserSelectedPropertyDocument = gql`
    mutation UpdateUserSelectedProperty($selected_property_id: uuid!, $id: uuid!) {
  update_user_by_pk(
    pk_columns: {id: $id}
    _set: {selected_property_id: $selected_property_id}
  ) {
    id
  }
}
    `;
export type UpdateUserSelectedPropertyMutationFn = Apollo.MutationFunction<UpdateUserSelectedPropertyMutation, UpdateUserSelectedPropertyMutationVariables>;

/**
 * __useUpdateUserSelectedPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateUserSelectedPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSelectedPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSelectedPropertyMutation, { data, loading, error }] = useUpdateUserSelectedPropertyMutation({
 *   variables: {
 *      selected_property_id: // value for 'selected_property_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserSelectedPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSelectedPropertyMutation, UpdateUserSelectedPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSelectedPropertyMutation, UpdateUserSelectedPropertyMutationVariables>(UpdateUserSelectedPropertyDocument, options);
      }
export type UpdateUserSelectedPropertyMutationHookResult = ReturnType<typeof useUpdateUserSelectedPropertyMutation>;
export type UpdateUserSelectedPropertyMutationResult = Apollo.MutationResult<UpdateUserSelectedPropertyMutation>;
export type UpdateUserSelectedPropertyMutationOptions = Apollo.BaseMutationOptions<UpdateUserSelectedPropertyMutation, UpdateUserSelectedPropertyMutationVariables>;
export const ReservationByIdDocument = gql`
    subscription ReservationById($id: uuid!) {
  reservation_by_pk(id: $id) {
    arrival_date
    departure_date
    note
    status
    pets
    client {
      id
      first_name
      last_name
      phone_number
      email
    }
    invoice {
      id
    }
    reservation_rooms {
      room {
        id
        room_type {
          room_type
        }
      }
    }
  }
}
    `;

/**
 * __useReservationByIdSubscription__
 *
 * To run a query within a React component, call `useReservationByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReservationByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReservationByIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<ReservationByIdSubscription, ReservationByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ReservationByIdSubscription, ReservationByIdSubscriptionVariables>(ReservationByIdDocument, options);
      }
export type ReservationByIdSubscriptionHookResult = ReturnType<typeof useReservationByIdSubscription>;
export type ReservationByIdSubscriptionResult = Apollo.SubscriptionResult<ReservationByIdSubscription>;
export const UpdateReservationDocument = gql`
    mutation UpdateReservation($id: uuid!, $reservation_set_input: reservation_set_input!, $reservation_rooms_insert_input: [reservation_rooms_insert_input!]!, $reservation_rooms_bool_exp: reservation_rooms_bool_exp!, $client_set_input: client_set_input!, $client_id: uuid!) {
  update_reservation_by_pk(pk_columns: {id: $id}, _set: $reservation_set_input) {
    __typename
  }
  insert_reservation_rooms(objects: $reservation_rooms_insert_input) {
    affected_rows
  }
  delete_reservation_rooms(where: $reservation_rooms_bool_exp) {
    affected_rows
  }
  ...update_client_by_pk_fragment
}
    ${Update_Client_By_Pk_FragmentFragmentDoc}`;
export type UpdateReservationMutationFn = Apollo.MutationFunction<UpdateReservationMutation, UpdateReservationMutationVariables>;

/**
 * __useUpdateReservationMutation__
 *
 * To run a mutation, you first call `useUpdateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationMutation, { data, loading, error }] = useUpdateReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reservation_set_input: // value for 'reservation_set_input'
 *      reservation_rooms_insert_input: // value for 'reservation_rooms_insert_input'
 *      reservation_rooms_bool_exp: // value for 'reservation_rooms_bool_exp'
 *      client_set_input: // value for 'client_set_input'
 *      client_id: // value for 'client_id'
 *   },
 * });
 */
export function useUpdateReservationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReservationMutation, UpdateReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReservationMutation, UpdateReservationMutationVariables>(UpdateReservationDocument, options);
      }
export type UpdateReservationMutationHookResult = ReturnType<typeof useUpdateReservationMutation>;
export type UpdateReservationMutationResult = Apollo.MutationResult<UpdateReservationMutation>;
export type UpdateReservationMutationOptions = Apollo.BaseMutationOptions<UpdateReservationMutation, UpdateReservationMutationVariables>;
export const DeleteReservationDocument = gql`
    mutation DeleteReservation($id: uuid!) {
  delete_reservation_rooms(where: {reservation_id: {_eq: $id}}) {
    affected_rows
  }
  delete_invoice(where: {reservation_id: {_eq: $id}}) {
    affected_rows
  }
  delete_reservation(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteReservationMutationFn = Apollo.MutationFunction<DeleteReservationMutation, DeleteReservationMutationVariables>;

/**
 * __useDeleteReservationMutation__
 *
 * To run a mutation, you first call `useDeleteReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReservationMutation, { data, loading, error }] = useDeleteReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReservationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReservationMutation, DeleteReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReservationMutation, DeleteReservationMutationVariables>(DeleteReservationDocument, options);
      }
export type DeleteReservationMutationHookResult = ReturnType<typeof useDeleteReservationMutation>;
export type DeleteReservationMutationResult = Apollo.MutationResult<DeleteReservationMutation>;
export type DeleteReservationMutationOptions = Apollo.BaseMutationOptions<DeleteReservationMutation, DeleteReservationMutationVariables>;
export const PropertyExpensesDocument = gql`
    subscription PropertyExpenses($id: uuid!) {
  property_by_pk(id: $id) {
    expenses(order_by: {created_at: desc}) {
      amount
      created_at
      id
      note
      property_id
      type
      updated_at
      currency_code
    }
  }
}
    `;

/**
 * __usePropertyExpensesSubscription__
 *
 * To run a query within a React component, call `usePropertyExpensesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePropertyExpensesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyExpensesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyExpensesSubscription(baseOptions: Apollo.SubscriptionHookOptions<PropertyExpensesSubscription, PropertyExpensesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PropertyExpensesSubscription, PropertyExpensesSubscriptionVariables>(PropertyExpensesDocument, options);
      }
export type PropertyExpensesSubscriptionHookResult = ReturnType<typeof usePropertyExpensesSubscription>;
export type PropertyExpensesSubscriptionResult = Apollo.SubscriptionResult<PropertyExpensesSubscription>;
export const InsertExpenseDocument = gql`
    mutation InsertExpense($amount: Int!, $note: String!, $property_id: uuid!, $type: String!, $currency_code: String!) {
  insert_expense_one(
    object: {amount: $amount, note: $note, property_id: $property_id, type: $type, currency_code: $currency_code}
  ) {
    id
  }
}
    `;
export type InsertExpenseMutationFn = Apollo.MutationFunction<InsertExpenseMutation, InsertExpenseMutationVariables>;

/**
 * __useInsertExpenseMutation__
 *
 * To run a mutation, you first call `useInsertExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExpenseMutation, { data, loading, error }] = useInsertExpenseMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      note: // value for 'note'
 *      property_id: // value for 'property_id'
 *      type: // value for 'type'
 *      currency_code: // value for 'currency_code'
 *   },
 * });
 */
export function useInsertExpenseMutation(baseOptions?: Apollo.MutationHookOptions<InsertExpenseMutation, InsertExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertExpenseMutation, InsertExpenseMutationVariables>(InsertExpenseDocument, options);
      }
export type InsertExpenseMutationHookResult = ReturnType<typeof useInsertExpenseMutation>;
export type InsertExpenseMutationResult = Apollo.MutationResult<InsertExpenseMutation>;
export type InsertExpenseMutationOptions = Apollo.BaseMutationOptions<InsertExpenseMutation, InsertExpenseMutationVariables>;
export const ExpenseTypesDocument = gql`
    subscription ExpenseTypes($_eq: uuid!) {
  expense(distinct_on: type, where: {property_id: {_eq: $_eq}}) {
    type
  }
}
    `;

/**
 * __useExpenseTypesSubscription__
 *
 * To run a query within a React component, call `useExpenseTypesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpenseTypesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseTypesSubscription({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useExpenseTypesSubscription(baseOptions: Apollo.SubscriptionHookOptions<ExpenseTypesSubscription, ExpenseTypesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpenseTypesSubscription, ExpenseTypesSubscriptionVariables>(ExpenseTypesDocument, options);
      }
export type ExpenseTypesSubscriptionHookResult = ReturnType<typeof useExpenseTypesSubscription>;
export type ExpenseTypesSubscriptionResult = Apollo.SubscriptionResult<ExpenseTypesSubscription>;
export const UpdatePriceDocument = gql`
    mutation UpdatePrice($id: uuid!, $amount: float8!) {
  update_room_price_by_pk(pk_columns: {id: $id}, _set: {amount: $amount}) {
    id
  }
}
    `;
export type UpdatePriceMutationFn = Apollo.MutationFunction<UpdatePriceMutation, UpdatePriceMutationVariables>;

/**
 * __useUpdatePriceMutation__
 *
 * To run a mutation, you first call `useUpdatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceMutation, { data, loading, error }] = useUpdatePriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUpdatePriceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePriceMutation, UpdatePriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePriceMutation, UpdatePriceMutationVariables>(UpdatePriceDocument, options);
      }
export type UpdatePriceMutationHookResult = ReturnType<typeof useUpdatePriceMutation>;
export type UpdatePriceMutationResult = Apollo.MutationResult<UpdatePriceMutation>;
export type UpdatePriceMutationOptions = Apollo.BaseMutationOptions<UpdatePriceMutation, UpdatePriceMutationVariables>;
export const CreatePriceDocument = gql`
    mutation CreatePrice($amount: float8!, $date: date!, $room_id: uuid!, $currency_code: String!) {
  insert_room_price_one(
    object: {room_id: $room_id, date: $date, amount: $amount, currency_code: $currency_code}
  ) {
    id
  }
}
    `;
export type CreatePriceMutationFn = Apollo.MutationFunction<CreatePriceMutation, CreatePriceMutationVariables>;

/**
 * __useCreatePriceMutation__
 *
 * To run a mutation, you first call `useCreatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPriceMutation, { data, loading, error }] = useCreatePriceMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      date: // value for 'date'
 *      room_id: // value for 'room_id'
 *      currency_code: // value for 'currency_code'
 *   },
 * });
 */
export function useCreatePriceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePriceMutation, CreatePriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePriceMutation, CreatePriceMutationVariables>(CreatePriceDocument, options);
      }
export type CreatePriceMutationHookResult = ReturnType<typeof useCreatePriceMutation>;
export type CreatePriceMutationResult = Apollo.MutationResult<CreatePriceMutation>;
export type CreatePriceMutationOptions = Apollo.BaseMutationOptions<CreatePriceMutation, CreatePriceMutationVariables>;
export const InvoiceDocument = gql`
    subscription Invoice($id: uuid!, $arrival_date: date!, $departure_date: date!) {
  reservation_by_pk(id: $id) {
    created_at
    arrival_date
    departure_date
    pets
    client {
      first_name
      last_name
      email
      phone_number
      property_id
      property {
        user {
          selected_locale
        }
        currency_code
      }
    }
    invoice {
      id
      number
      discount
    }
    reservation_rooms {
      room {
        id
        room_number
        room_type {
          room_type
          max_occupancy
        }
        room_prices(
          order_by: {date: asc}
          where: {_and: [{date: {_gte: $arrival_date}}, {date: {_lte: $departure_date}}]}
        ) {
          id
          room_id
          amount
          date
        }
      }
    }
  }
}
    `;

/**
 * __useInvoiceSubscription__
 *
 * To run a query within a React component, call `useInvoiceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      arrival_date: // value for 'arrival_date'
 *      departure_date: // value for 'departure_date'
 *   },
 * });
 */
export function useInvoiceSubscription(baseOptions: Apollo.SubscriptionHookOptions<InvoiceSubscription, InvoiceSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InvoiceSubscription, InvoiceSubscriptionVariables>(InvoiceDocument, options);
      }
export type InvoiceSubscriptionHookResult = ReturnType<typeof useInvoiceSubscription>;
export type InvoiceSubscriptionResult = Apollo.SubscriptionResult<InvoiceSubscription>;
export const DeletePriceDocument = gql`
    mutation DeletePrice($id: uuid!) {
  delete_room_price_by_pk(id: $id) {
    id
  }
}
    `;
export type DeletePriceMutationFn = Apollo.MutationFunction<DeletePriceMutation, DeletePriceMutationVariables>;

/**
 * __useDeletePriceMutation__
 *
 * To run a mutation, you first call `useDeletePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePriceMutation, { data, loading, error }] = useDeletePriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePriceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePriceMutation, DeletePriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePriceMutation, DeletePriceMutationVariables>(DeletePriceDocument, options);
      }
export type DeletePriceMutationHookResult = ReturnType<typeof useDeletePriceMutation>;
export type DeletePriceMutationResult = Apollo.MutationResult<DeletePriceMutation>;
export type DeletePriceMutationOptions = Apollo.BaseMutationOptions<DeletePriceMutation, DeletePriceMutationVariables>;
export const PropertyStatsDocument = gql`
    subscription PropertyStats($id: uuid!) {
  property_by_pk(id: $id) {
    name
    email
    phone_number
    address
    check_in
    check_out
    iban
    bic
    legal_entity
    legal_entity_id
    pet_price
    down_payment
    currency_code
  }
}
    `;

/**
 * __usePropertyStatsSubscription__
 *
 * To run a query within a React component, call `usePropertyStatsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePropertyStatsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyStatsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyStatsSubscription(baseOptions: Apollo.SubscriptionHookOptions<PropertyStatsSubscription, PropertyStatsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PropertyStatsSubscription, PropertyStatsSubscriptionVariables>(PropertyStatsDocument, options);
      }
export type PropertyStatsSubscriptionHookResult = ReturnType<typeof usePropertyStatsSubscription>;
export type PropertyStatsSubscriptionResult = Apollo.SubscriptionResult<PropertyStatsSubscription>;
export const UpdatePropertyDocument = gql`
    mutation UpdateProperty($id: uuid!, $address: String!, $bic: String!, $check_in: String!, $check_out: String!, $email: String!, $iban: String!, $legal_entity: String!, $legal_entity_id: String!, $name: String!, $phone_number: String!, $pet_price: float8, $down_payment: float8, $currency_code: String!) {
  update_property_by_pk(
    pk_columns: {id: $id}
    _set: {address: $address, bic: $bic, check_in: $check_in, check_out: $check_out, email: $email, iban: $iban, legal_entity: $legal_entity, legal_entity_id: $legal_entity_id, name: $name, phone_number: $phone_number, pet_price: $pet_price, down_payment: $down_payment, currency_code: $currency_code}
  ) {
    id
  }
}
    `;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<UpdatePropertyMutation, UpdatePropertyMutationVariables>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      address: // value for 'address'
 *      bic: // value for 'bic'
 *      check_in: // value for 'check_in'
 *      check_out: // value for 'check_out'
 *      email: // value for 'email'
 *      iban: // value for 'iban'
 *      legal_entity: // value for 'legal_entity'
 *      legal_entity_id: // value for 'legal_entity_id'
 *      name: // value for 'name'
 *      phone_number: // value for 'phone_number'
 *      pet_price: // value for 'pet_price'
 *      down_payment: // value for 'down_payment'
 *      currency_code: // value for 'currency_code'
 *   },
 * });
 */
export function useUpdatePropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropertyMutation, UpdatePropertyMutationVariables>(UpdatePropertyDocument, options);
      }
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>;
export type UpdatePropertyMutationResult = Apollo.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>;
export const DeletePropertyDocument = gql`
    mutation DeleteProperty($id: uuid!) {
  delete_property_by_pk(id: $id) {
    id
  }
}
    `;
export type DeletePropertyMutationFn = Apollo.MutationFunction<DeletePropertyMutation, DeletePropertyMutationVariables>;

/**
 * __useDeletePropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyMutation, { data, loading, error }] = useDeletePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePropertyMutation(baseOptions?: Apollo.MutationHookOptions<DeletePropertyMutation, DeletePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePropertyMutation, DeletePropertyMutationVariables>(DeletePropertyDocument, options);
      }
export type DeletePropertyMutationHookResult = ReturnType<typeof useDeletePropertyMutation>;
export type DeletePropertyMutationResult = Apollo.MutationResult<DeletePropertyMutation>;
export type DeletePropertyMutationOptions = Apollo.BaseMutationOptions<DeletePropertyMutation, DeletePropertyMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($id: uuid!, $discount: float8) {
  update_invoice_by_pk(pk_columns: {id: $id}, _set: {discount: $discount}) {
    id
  }
}
    `;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const GetInvoiceByIdDocument = gql`
    subscription GetInvoiceById($id: uuid!) {
  invoice_by_pk(id: $id) {
    reservation {
      id
      arrival_date
      departure_date
      property {
        user {
          selected_locale
          selected_property_id
        }
      }
    }
  }
}
    `;

/**
 * __useGetInvoiceByIdSubscription__
 *
 * To run a query within a React component, call `useGetInvoiceByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceByIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetInvoiceByIdSubscription, GetInvoiceByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetInvoiceByIdSubscription, GetInvoiceByIdSubscriptionVariables>(GetInvoiceByIdDocument, options);
      }
export type GetInvoiceByIdSubscriptionHookResult = ReturnType<typeof useGetInvoiceByIdSubscription>;
export type GetInvoiceByIdSubscriptionResult = Apollo.SubscriptionResult<GetInvoiceByIdSubscription>;
export const SetSelectedLolcaleDocument = gql`
    mutation SetSelectedLolcale($id: uuid!, $selected_locale: String!) {
  update_user_by_pk(
    pk_columns: {id: $id}
    _set: {selected_locale: $selected_locale}
  ) {
    id
  }
}
    `;
export type SetSelectedLolcaleMutationFn = Apollo.MutationFunction<SetSelectedLolcaleMutation, SetSelectedLolcaleMutationVariables>;

/**
 * __useSetSelectedLolcaleMutation__
 *
 * To run a mutation, you first call `useSetSelectedLolcaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSelectedLolcaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSelectedLolcaleMutation, { data, loading, error }] = useSetSelectedLolcaleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      selected_locale: // value for 'selected_locale'
 *   },
 * });
 */
export function useSetSelectedLolcaleMutation(baseOptions?: Apollo.MutationHookOptions<SetSelectedLolcaleMutation, SetSelectedLolcaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSelectedLolcaleMutation, SetSelectedLolcaleMutationVariables>(SetSelectedLolcaleDocument, options);
      }
export type SetSelectedLolcaleMutationHookResult = ReturnType<typeof useSetSelectedLolcaleMutation>;
export type SetSelectedLolcaleMutationResult = Apollo.MutationResult<SetSelectedLolcaleMutation>;
export type SetSelectedLolcaleMutationOptions = Apollo.BaseMutationOptions<SetSelectedLolcaleMutation, SetSelectedLolcaleMutationVariables>;
export const GetRoomTypeDocument = gql`
    subscription GetRoomType($id: uuid!) {
  room_type_by_pk(id: $id) {
    room_type
    max_occupancy
  }
}
    `;

/**
 * __useGetRoomTypeSubscription__
 *
 * To run a query within a React component, call `useGetRoomTypeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomTypeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomTypeSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoomTypeSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetRoomTypeSubscription, GetRoomTypeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetRoomTypeSubscription, GetRoomTypeSubscriptionVariables>(GetRoomTypeDocument, options);
      }
export type GetRoomTypeSubscriptionHookResult = ReturnType<typeof useGetRoomTypeSubscription>;
export type GetRoomTypeSubscriptionResult = Apollo.SubscriptionResult<GetRoomTypeSubscription>;
export const UpdateRoomTypeDocument = gql`
    mutation UpdateRoomType($id: uuid!, $_set: room_type_set_input!) {
  update_room_type_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}
    `;
export type UpdateRoomTypeMutationFn = Apollo.MutationFunction<UpdateRoomTypeMutation, UpdateRoomTypeMutationVariables>;

/**
 * __useUpdateRoomTypeMutation__
 *
 * To run a mutation, you first call `useUpdateRoomTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomTypeMutation, { data, loading, error }] = useUpdateRoomTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateRoomTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomTypeMutation, UpdateRoomTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomTypeMutation, UpdateRoomTypeMutationVariables>(UpdateRoomTypeDocument, options);
      }
export type UpdateRoomTypeMutationHookResult = ReturnType<typeof useUpdateRoomTypeMutation>;
export type UpdateRoomTypeMutationResult = Apollo.MutationResult<UpdateRoomTypeMutation>;
export type UpdateRoomTypeMutationOptions = Apollo.BaseMutationOptions<UpdateRoomTypeMutation, UpdateRoomTypeMutationVariables>;
export const GetRoomByIdDocument = gql`
    subscription GetRoomById($id: uuid!) {
  room_by_pk(id: $id) {
    id
    room_number
    room_type_id
  }
}
    `;

/**
 * __useGetRoomByIdSubscription__
 *
 * To run a query within a React component, call `useGetRoomByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoomByIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetRoomByIdSubscription, GetRoomByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetRoomByIdSubscription, GetRoomByIdSubscriptionVariables>(GetRoomByIdDocument, options);
      }
export type GetRoomByIdSubscriptionHookResult = ReturnType<typeof useGetRoomByIdSubscription>;
export type GetRoomByIdSubscriptionResult = Apollo.SubscriptionResult<GetRoomByIdSubscription>;
export const UpdateRoomByIdDocument = gql`
    mutation UpdateRoomById($id: uuid!, $_set: room_set_input!) {
  update_room_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}
    `;
export type UpdateRoomByIdMutationFn = Apollo.MutationFunction<UpdateRoomByIdMutation, UpdateRoomByIdMutationVariables>;

/**
 * __useUpdateRoomByIdMutation__
 *
 * To run a mutation, you first call `useUpdateRoomByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomByIdMutation, { data, loading, error }] = useUpdateRoomByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateRoomByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomByIdMutation, UpdateRoomByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomByIdMutation, UpdateRoomByIdMutationVariables>(UpdateRoomByIdDocument, options);
      }
export type UpdateRoomByIdMutationHookResult = ReturnType<typeof useUpdateRoomByIdMutation>;
export type UpdateRoomByIdMutationResult = Apollo.MutationResult<UpdateRoomByIdMutation>;
export type UpdateRoomByIdMutationOptions = Apollo.BaseMutationOptions<UpdateRoomByIdMutation, UpdateRoomByIdMutationVariables>;
export const DeleteRoomByIdDocument = gql`
    mutation DeleteRoomById($id: uuid!) {
  delete_room(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteRoomByIdMutationFn = Apollo.MutationFunction<DeleteRoomByIdMutation, DeleteRoomByIdMutationVariables>;

/**
 * __useDeleteRoomByIdMutation__
 *
 * To run a mutation, you first call `useDeleteRoomByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomByIdMutation, { data, loading, error }] = useDeleteRoomByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoomByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoomByIdMutation, DeleteRoomByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoomByIdMutation, DeleteRoomByIdMutationVariables>(DeleteRoomByIdDocument, options);
      }
export type DeleteRoomByIdMutationHookResult = ReturnType<typeof useDeleteRoomByIdMutation>;
export type DeleteRoomByIdMutationResult = Apollo.MutationResult<DeleteRoomByIdMutation>;
export type DeleteRoomByIdMutationOptions = Apollo.BaseMutationOptions<DeleteRoomByIdMutation, DeleteRoomByIdMutationVariables>;
export const DeleteRoomTypeByIdDocument = gql`
    mutation DeleteRoomTypeById($id: uuid!) {
  delete_room_type(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteRoomTypeByIdMutationFn = Apollo.MutationFunction<DeleteRoomTypeByIdMutation, DeleteRoomTypeByIdMutationVariables>;

/**
 * __useDeleteRoomTypeByIdMutation__
 *
 * To run a mutation, you first call `useDeleteRoomTypeByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomTypeByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomTypeByIdMutation, { data, loading, error }] = useDeleteRoomTypeByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoomTypeByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoomTypeByIdMutation, DeleteRoomTypeByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoomTypeByIdMutation, DeleteRoomTypeByIdMutationVariables>(DeleteRoomTypeByIdDocument, options);
      }
export type DeleteRoomTypeByIdMutationHookResult = ReturnType<typeof useDeleteRoomTypeByIdMutation>;
export type DeleteRoomTypeByIdMutationResult = Apollo.MutationResult<DeleteRoomTypeByIdMutation>;
export type DeleteRoomTypeByIdMutationOptions = Apollo.BaseMutationOptions<DeleteRoomTypeByIdMutation, DeleteRoomTypeByIdMutationVariables>;
export const GetClientByIdDocument = gql`
    subscription GetClientById($id: uuid!) {
  client_by_pk(id: $id) {
    email
    first_name
    last_name
    phone_number
  }
}
    `;

/**
 * __useGetClientByIdSubscription__
 *
 * To run a query within a React component, call `useGetClientByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetClientByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientByIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetClientByIdSubscription, GetClientByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetClientByIdSubscription, GetClientByIdSubscriptionVariables>(GetClientByIdDocument, options);
      }
export type GetClientByIdSubscriptionHookResult = ReturnType<typeof useGetClientByIdSubscription>;
export type GetClientByIdSubscriptionResult = Apollo.SubscriptionResult<GetClientByIdSubscription>;
export const UpdateClientByIdDocument = gql`
    mutation UpdateClientById($client_set_input: client_set_input!, $client_id: uuid!) {
  ...update_client_by_pk_fragment
}
    ${Update_Client_By_Pk_FragmentFragmentDoc}`;
export type UpdateClientByIdMutationFn = Apollo.MutationFunction<UpdateClientByIdMutation, UpdateClientByIdMutationVariables>;

/**
 * __useUpdateClientByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientByIdMutation, { data, loading, error }] = useUpdateClientByIdMutation({
 *   variables: {
 *      client_set_input: // value for 'client_set_input'
 *      client_id: // value for 'client_id'
 *   },
 * });
 */
export function useUpdateClientByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientByIdMutation, UpdateClientByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientByIdMutation, UpdateClientByIdMutationVariables>(UpdateClientByIdDocument, options);
      }
export type UpdateClientByIdMutationHookResult = ReturnType<typeof useUpdateClientByIdMutation>;
export type UpdateClientByIdMutationResult = Apollo.MutationResult<UpdateClientByIdMutation>;
export type UpdateClientByIdMutationOptions = Apollo.BaseMutationOptions<UpdateClientByIdMutation, UpdateClientByIdMutationVariables>;
export const DeleteClientByIdDocument = gql`
    mutation DeleteClientById($id: uuid!) {
  delete_client_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteClientByIdMutationFn = Apollo.MutationFunction<DeleteClientByIdMutation, DeleteClientByIdMutationVariables>;

/**
 * __useDeleteClientByIdMutation__
 *
 * To run a mutation, you first call `useDeleteClientByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientByIdMutation, { data, loading, error }] = useDeleteClientByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientByIdMutation, DeleteClientByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientByIdMutation, DeleteClientByIdMutationVariables>(DeleteClientByIdDocument, options);
      }
export type DeleteClientByIdMutationHookResult = ReturnType<typeof useDeleteClientByIdMutation>;
export type DeleteClientByIdMutationResult = Apollo.MutationResult<DeleteClientByIdMutation>;
export type DeleteClientByIdMutationOptions = Apollo.BaseMutationOptions<DeleteClientByIdMutation, DeleteClientByIdMutationVariables>;
export const CreateClientDocument = gql`
    mutation CreateClient($object: client_insert_input!) {
  insert_client_one(object: $object) {
    id
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const RoomDataDocument = gql`
    subscription RoomData($where: room_bool_exp = {}, $where1: room_price_bool_exp = {}) {
  room(where: $where) {
    id
    room_prices(where: $where1) {
      date
      amount
    }
  }
}
    `;

/**
 * __useRoomDataSubscription__
 *
 * To run a query within a React component, call `useRoomDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomDataSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      where1: // value for 'where1'
 *   },
 * });
 */
export function useRoomDataSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RoomDataSubscription, RoomDataSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RoomDataSubscription, RoomDataSubscriptionVariables>(RoomDataDocument, options);
      }
export type RoomDataSubscriptionHookResult = ReturnType<typeof useRoomDataSubscription>;
export type RoomDataSubscriptionResult = Apollo.SubscriptionResult<RoomDataSubscription>;