import {
  AppBar,
  Box,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
  ListItemIcon,
  SwipeableDrawer,
} from "@mui/material";
import { Button, ListItemButton } from "gatsby-theme-material-ui";
import React from "react";
import {
  CalendarMonth,
  Dashboard,
  ListAlt,
  Menu,
  Person,
  Group,
  Business,
  BedroomParent,
  Hotel,
  Close,
  TrendingDown,
  Receipt,
  PriceChange,
} from "@mui/icons-material";
import { useAuthorizer } from "@authorizerdev/authorizer-react";
import { useLocation } from "@reach/router";
import { useSelectedPropertyContext } from "./SelectedPropertyProvider";
import { useStripeCustomerIdSubscription } from "../__generated__/types";
import { translations } from "../translations";
import { useStaticQuery, graphql } from "gatsby";
import { useVersionNotificationEffect } from "../hooks/useVersionNotificationEffect";

const ROUTES_ICONS = {
  "/": <Dashboard />,
  "/schedule": <CalendarMonth />,
  "/reservations": <ListAlt />,
  "/clients": <Group />,
  "/rooms": <BedroomParent />,
  "/room-type": <Hotel />,
  "/property": <Business />,
  "/expense": <TrendingDown />,
  // "/invoices": <Receipt />,
  "/prices": <PriceChange />,
};

const PropertySelector = () => {
  const { selected, setSelected, property, selected_locale } =
    useSelectedPropertyContext();

  if (!selected || !property) return null;
  return (
    <FormControl>
      <InputLabel>{translations[selected_locale].property}</InputLabel>
      <Select
        value={selected}
        label={translations[selected_locale].property}
        sx={{ backgroundColor: "white" }}
        onChange={(event) => {
          setSelected(event.target.value);
        }}
        fullWidth
      >
        {property.map(({ id, name }) => (
          <MenuItem key={id} value={id} selected>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const UserMenuItem = () => {
  const { user, loading, authorizerRef, setUser } = useAuthorizer();

  const handleLogout = async () => {
    await authorizerRef.logout();
    setUser(null);
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { selected_locale } = useSelectedPropertyContext();
  return (
    <>
      <Tooltip title={user?.email}>
        <IconButton sx={{ color: "white" }} onClick={handleClick}>
          <Person />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ width: 250 }}>
          <ListItem disablePadding>
            <ListItemButton to="/profile">
              <ListItemText primary={translations[selected_locale].profile} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemText
                primary={translations[selected_locale].signout_button}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

const Navigation = () => {
  const location = useLocation();
  const { user, loading, token } = useAuthorizer();
  const [open, setOpen] = React.useState(false);
  const { data } = useStripeCustomerIdSubscription({
    variables: { id: user?.id },
    skip: !user?.id ? true : false,
  });
  const isOnboardedDisabled = !data?.user_by_pk?.has_onboarded;
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const { selected_locale } = useSelectedPropertyContext();
  const ROUTES = {
    "/": translations[selected_locale].home,
    "/schedule": translations[selected_locale].schedule,
    "/reservations": translations[selected_locale].reservations,
    "/clients": translations[selected_locale].clients,
    "/rooms": translations[selected_locale].rooms,
    "/room-type": translations[selected_locale].room_types,
    "/property": translations[selected_locale].properties,
    "/expense": translations[selected_locale].expenses,
    // "/invoices": translations[selected_locale].invoices,
    "/prices": translations[selected_locale].prices,
  };
  const { site } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          version
        }
      }
    }
  `);
  useVersionNotificationEffect(site.siteMetadata.version);
  if (
    location.pathname === "/login" ||
    (location.pathname.includes("/invoice") && !user)
  )
    return null;
  return (
    <>
      <AppBar
        position="static"
        sx={{
          "@media print": {
            display: "none",
          },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => setOpen((o) => !o)}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {ROUTES[location.pathname]}
            {location.pathname === "/release-notes" &&
              translations[selected_locale].release_notes}
          </Typography>
          {!loading && !user && (
            <Button variant="contained" color="secondary" to="/login">
              {translations[selected_locale].login_button}
            </Button>
          )}
          {!loading && user && <UserMenuItem />}
        </Toolbar>
      </AppBar>
      <Box>
        <SwipeableDrawer
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Villy.app
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Toolbar>
          <List
            sx={{
              width: 250,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <ListItem>{!loading && user && <PropertySelector />}</ListItem>
            {Object.entries(ROUTES).map(([route, text], index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  to={route}
                  disabled={
                    isOnboardedDisabled &&
                    (route === "/schedule" ||
                      route === "/reservations" ||
                      route === "/clients" ||
                      route === "/rooms" ||
                      route === "/room-type" ||
                      route === "/property" ||
                      route === "/expense" ||
                      route === "/invoices" ||
                      route === "/prices")
                  }
                >
                  <ListItemIcon>{ROUTES_ICONS[route]}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
            <Box flex={1} />
            <ListItem disablePadding>
              <ListItemButton to="/release-notes">
                <ListItemText
                  primary={translations[selected_locale].release_notes}
                />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={`${translations[selected_locale].version} ${site.siteMetadata.version}`}
              />
            </ListItem>
          </List>
        </SwipeableDrawer>
      </Box>
    </>
  );
};

export default Navigation;
