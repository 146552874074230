import React from "react";
import Navigation from "./Navigation";
import { LocationProvider } from "@reach/router";
import { ApolloProvider } from "./../apollo";
import { SelectedPropertyProvider } from "./SelectedPropertyProvider";
import { SnackbarProvider } from "notistack";

import { AuthorizerProvider } from "../authorizer";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <p>{this.state.error?.message}</p>
          <p>{this.state.error?.stack}</p>
          <p>{this.state.errorInfo?.componentStack}</p>
        </>
      );
    }

    return this.props.children;
  }
}

export const AppShell = ({ children }) => (
  <ErrorBoundary>
    <AuthorizerProvider>
      <SnackbarProvider>
        <ApolloProvider>
          <SelectedPropertyProvider>
            <LocationProvider>
              <Navigation />
            </LocationProvider>
            {children}
          </SelectedPropertyProvider>
        </ApolloProvider>
      </SnackbarProvider>
    </AuthorizerProvider>
  </ErrorBoundary>
);
