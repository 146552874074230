exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-client-[id]-tsx": () => import("./../../../src/pages/client/[id].tsx" /* webpackChunkName: "component---src-pages-client-[id]-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-expense-tsx": () => import("./../../../src/pages/expense.tsx" /* webpackChunkName: "component---src-pages-expense-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-[id]-tsx": () => import("./../../../src/pages/invoice/[id].tsx" /* webpackChunkName: "component---src-pages-invoice-[id]-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-prices-tsx": () => import("./../../../src/pages/prices.tsx" /* webpackChunkName: "component---src-pages-prices-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-property-[id]-tsx": () => import("./../../../src/pages/property/[id].tsx" /* webpackChunkName: "component---src-pages-property-[id]-tsx" */),
  "component---src-pages-property-tsx": () => import("./../../../src/pages/property.tsx" /* webpackChunkName: "component---src-pages-property-tsx" */),
  "component---src-pages-release-notes-tsx": () => import("./../../../src/pages/release-notes.tsx" /* webpackChunkName: "component---src-pages-release-notes-tsx" */),
  "component---src-pages-reservations-[id]-tsx": () => import("./../../../src/pages/reservations/[id].tsx" /* webpackChunkName: "component---src-pages-reservations-[id]-tsx" */),
  "component---src-pages-reservations-tsx": () => import("./../../../src/pages/reservations.tsx" /* webpackChunkName: "component---src-pages-reservations-tsx" */),
  "component---src-pages-room-type-[id]-tsx": () => import("./../../../src/pages/room-type/[id].tsx" /* webpackChunkName: "component---src-pages-room-type-[id]-tsx" */),
  "component---src-pages-room-type-tsx": () => import("./../../../src/pages/room-type.tsx" /* webpackChunkName: "component---src-pages-room-type-tsx" */),
  "component---src-pages-rooms-[id]-tsx": () => import("./../../../src/pages/rooms/[id].tsx" /* webpackChunkName: "component---src-pages-rooms-[id]-tsx" */),
  "component---src-pages-rooms-tsx": () => import("./../../../src/pages/rooms.tsx" /* webpackChunkName: "component---src-pages-rooms-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */)
}

