import { bgBG, enUS } from "@mui/x-data-grid";

export const translations = {
  "bg-BG": {
    locale: "Български",
    personal_information: "Персонална информация",
    given_name: "Име",
    family_name: "Фамилно име",
    email: "Емайл",
    language: "Език",
    submit: "Запиши",
    payment_plan: "Aбонаментен план",
    next_payment: "Следващо плащане",
    client_portal: "Клиентски портал",
    dayjs_locale: import("dayjs/locale/bg"),
    dayjs_localeCode: "bg",
    base_payment_plane: "Базов план",
    pay_button: "Плати",
    month: "месец",
    year: "година",
    active: "активен",
    login_button: "Влез",
    signout_button: "Излез",
    profile: "Профил",
    property: "Имот",
    home: "Начало",
    schedule: "График",
    reservations: "Резервации",
    clients: "Клиенти",
    rooms: "Стаи",
    room_types: "Видове стаи",
    properties: "Имоти",
    expenses: "Разходи",
    invoices: "Фактури",
    prices: "Ценоразпис",
    new_reservation: "Нова резервация",
    reservation_success: "Резервацията е записана",
    client_phone_number_key: "Телефонният номер трябва да бъде уникален",
    client_email_key: "Емайлът трябва да бъде уникален",
    arrivals: "Пристигащи",
    departures: "Заминаващи",
    waiting_desposit: "Чакащи депозит",
    waiting_full_payment: "Чакащи пълно плащане",
    no_arrival_reservations: "Няма пристигащи резервации",
    no_departure_reservations: "Няма заминаващи резервации",
    no_pending_deposit: "Няма чакащи капаро",
    no_pending_full_payment: "Няма чакащи за пълно плащане",
    welcome: "Добре дошли!",
    onboarding_text:
      "Добавете имот, видове стаи и стаи, за да започнете да използвате системата!",
    setup_payment: "Настройка на плащането",
    add_property: "Добавете имот",
    add_room_types: "Добавете видове стаи",
    add_rooms: "Добавете стаи",
    no_added_rooms: "Няма добавени стаи",
    number_label: "№",
    deposit: "Депозит",
    confirmed: "Потвърдена",
    payed: "Платена",
    required_field: "Това поле е задължително",
    room_field_atleast_one_required:
      "Полето стаи трябва да има поне 1 добавена стая",
    departure_date_must_after_arrival_date:
      "Полето за дата на заминаване трябва да е по-късно от датата на пристигане",
    invalid_email: "Моля, въведете валиден имейл",
    arrival_date: "Настаняване",
    departure_date: "Напускане",
    available_rooms: "Свободни стаи",
    no_available_rooms: "Няма свободни стаи",
    pets: "Домашни любимци",
    status: "Статус",
    note: "Бележка",
    client: "Клиент",
    client_new: "Нов клиент",
    phone_number: "Телефон",
    delete: "Изтрий",
    client_name: "Клиентско име",
    created_at: "Съставена на",
    updated_at: "Редактирана на",
    update: "Редактирай",
    add_room: "Нова стая",
    room_type: "Вид стая",
    max_occupancy: "Максимална заетост",
    add_room_type: "Нов вид стая",
    is_succesfully_saved: "е успешно добавен",
    property_name: "Име",
    legal_entity: "Юридическо лице",
    legal_entity_id: "ЕИК/ПИК/БУЛСТАТ/ЕГН",
    address: "Адрес",
    check_in: "Настаняване",
    check_out: "Напускане",
    pet_price: "Цена домашни любимци",
    type: "Тип",
    amount: "Сума",
    new: "Нов",
    add_expense: "Нов разход",
    expense_saved: "Разходът е записан",
    datagrid: bgBG,
    reservation_updated: "Резервацията е променена",
    name_prompt: "Напишете името на клиента",
    invoice: "Фактура",
    view: "виж",
    is_succesfully_updated: "е успешно променен",
    reservation: "Резервация",
    send: "Изпрати",
    issue_date: "Дата на издаване",
    due_date: "Падежна дата",
    for: "за",
    payment: "Плащане",
    overnight_stays: "Нощувки",
    number_of_people: "Брой хора",
    price_of_overnight_stay: "Цена на нощувка",
    total: "Общо",
    to: "до",
    from: "от",
    pet_fee: "Такса домашен любимец",
    discount: "Намаление",
    no_discount: "Без намаление",
    remaining: "Остатък",
    invoice_message:
      "Благодарим ви, че избрахте да отседнете при нас, надяваме се да ви посрещнем отново в бъдеще.",
    room_number: "Стая номер",
    number_positive: "трябва да е равно или по-голямо от 0",
    version: "версия",
    new_version_notification:
      "Налична е нова версия! Моля, опреснете страницата.",
    refresh: "Опресни",
    release_notes: "Регистър на промените",
    is_succesfully_deleted: "е успешно изтрит",
    print: "Принтирай",
    room: "стая",
  },
  "en-US": {
    locale: "English",
    personal_information: "Personal information",
    given_name: "Name",
    family_name: "Family name",
    email: "Email",
    language: "Language",
    submit: "Submit",
    payment_plan: "Subscription plan",
    next_payment: "Next payment",
    client_portal: "Client portal",
    dayjs_locale: import("dayjs/locale/en"),
    dayjs_localeCode: "en",
    base_payment_plane: "Base plan",
    pay_button: "Pay",
    month: "month",
    year: "year",
    active: "active",
    login_button: "Sign in",
    signout_button: "Sign out",
    profile: "Profile",
    property: "Property",
    home: "Home",
    schedule: "Schedule",
    reservations: "Reservations",
    clients: "Clients",
    rooms: "Rooms",
    room_types: "Room types",
    properties: "Properties",
    expenses: "Expenses",
    invoices: "Invoices",
    prices: "Prices",
    new_reservation: "Add reservation",
    reservation_success: "The reservation is saved",
    client_phone_number_key: "The phone number must be unique",
    client_email_key: "Email must be unique",
    arrivals: "Arrivals",
    departures: "Departures",
    waiting_desposit: "Pending deposit",
    waiting_full_payment: "Pending full payment",
    no_arrival_reservations: "There are no incoming bookings",
    no_departure_reservations: "There are no outbound bookings",
    no_pending_deposit: "There are no down payments pending",
    no_pending_full_payment: "There are no waiting for full payment",
    welcome: "Welcome",
    onboarding_text:
      "Add property, room types and rooms to start using the system!",
    setup_payment: "Setup payment",
    add_property: "Add property",
    add_room_types: "Add room types",
    add_rooms: "Add rooms",
    no_added_rooms: "No rooms added",
    number_label: "No.",
    deposit: "Deposit",
    confirmed: "Confirmed",
    payed: "Payed",
    required_field: "This field is required",
    room_field_atleast_one_required:
      "Rooms field must have at least 1 added room",
    departure_date_must_after_arrival_date:
      "Departure date field must be later than arrival date",
    invalid_email: "Please enter valid email",
    arrival_date: "Arrival date",
    departure_date: "Departure date",
    available_rooms: "Available rooms",
    no_available_rooms: "Тhere are no available rooms",
    pets: "Pets",
    status: "Status",
    note: "Note",
    client: "Client",
    client_new: "New client",
    phone_number: "Phone number",
    delete: "Delete",
    client_name: "Client name",
    created_at: "Created at",
    updated_at: "Updated at",
    update: "Update",
    add_room: "Add room",
    room_type: "Room type",
    max_occupancy: "Max occupancy",
    add_room_type: "Add room type",
    is_succesfully_saved: "is successfully saved",
    property_name: "Name",
    legal_entity: "Legal name",
    legal_entity_id: "Unique identifier",
    address: "Address",
    check_in: "Check in",
    check_out: "Check out",
    pet_price: "Pet price",
    type: "Type",
    amount: "Amount",
    new: "New",
    add_expense: "Add expense",
    expense_saved: "The expense is saved",
    datagrid: enUS,
    reservation_updated: "The reservation is updated",
    name_prompt: "Write the name of the client",
    invoice: "Invoice",
    view: "view",
    is_succesfully_updated: "is successfully updated",
    reservation: "Reservation",
    send: "Send",
    issue_date: "Issue date",
    due_date: "Due date",
    for: "for",
    payment: "Payment",
    overnight_stays: "Оvernight stays",
    number_of_people: "Number of people",
    price_of_overnight_stay: "Price per night",
    total: "Total",
    to: "to",
    from: "from",
    pet_fee: "Pet fee",
    discount: "Discount",
    no_discount: "No discount",
    remaining: "Remaining",
    invoice_message:
      "Thank you for choosing to stay with us, we hope to welcome you again in the future.",
    room_number: "Room number",
    number_positive: "must be equal or larger than 0",
    version: "version",
    new_version_notification: "New version available! Please refresh the page.",
    refresh: "Refresh",
    release_notes: "Release notes",
    is_succesfully_deleted: "is succesfully deleted",
    print: "Print",
    room: "room",
  },
  "hr-HR": {
    locale: "Hrvatski",
    personal_information: "Osobne informacije",
    given_name: "Ime",
    family_name: "Prezime",
    email: "E-pošta",
    language: "Jezik",
    submit: "podnijeti",
    payment_plan: "Plan pretplate",
    next_payment: "Sljedeća uplata",
    client_portal: "Portal za klijente",
    dayjs_locale: import("dayjs/locale/hr"),
    dayjs_localeCode: "hr",
    base_payment_plane: "Osnovni plan",
    pay_button: "Platiti",
    month: "mjesec",
    year: "godina",
    active: "aktivan",
    login_button: "Prijaviti se",
    signout_button: "Odjavi se",
    profile: "Profil",
    property: "Vlasništvo",
    home: "Dom",
    schedule: "Raspored",
    reservations: "Rezervacije",
    clients: "Klijenti",
    rooms: "Sobe",
    room_types: "Vrste soba",
    properties: "Svojstva",
    expenses: "Troškovi",
    invoices: "Fakture",
    prices: "Cijene",
    new_reservation: "Dodajte rezervaciju",
    reservation_success: "Rezervacija je spremljena",
    client_phone_number_key: "Telefonski broj mora biti jedinstven",
    client_email_key: "Email mora biti jedinstven",
    arrivals: "Dolasci",
    departures: "Odlasci",
    waiting_desposit: "Polog na čekanju",
    waiting_full_payment: "Čeka se cjelovito plaćanje",
    no_arrival_reservations: "Nema dolaznih rezervacija",
    no_departure_reservations: "Nema odlaznih rezervacija",
    no_pending_deposit: "Nema predujma na čekanju",
    no_pending_full_payment: "Nema čekanja na punu isplatu",
    welcome: "Dobrodošli",
    onboarding_text:
      "Dodajte nekretninu, tipove soba i sobe kako biste počeli koristiti sustav!",
    setup_payment: "Postavite plaćanje",
    add_property: "Dodaj imovinu",
    add_room_types: "Dodajte vrste soba",
    add_rooms: "Dodajte sobe",
    no_added_rooms: "Nema dodanih soba",
    number_label: "Ne.",
    deposit: "Polog",
    confirmed: "Potvrđeno",
    payed: "Plaćeno",
    required_field: "ovo polje je obavezno",
    room_field_atleast_one_required:
      "Polje Sobe mora imati barem 1 dodanu sobu",
    departure_date_must_after_arrival_date:
      "Polje za datum polaska mora biti kasnije od datuma dolaska",
    invalid_email: "Unesite valjanu e-poštu",
    arrival_date: "Datum dolaska",
    departure_date: "Datum polaska",
    available_rooms: "Slobodne sobe",
    no_available_rooms: "Nema slobodnih soba",
    pets: "Kućni ljubimci",
    status: "Status",
    note: "Bilješka",
    client: "Klijent",
    client_new: "Novi klijent",
    phone_number: "Broj telefona",
    delete: "Izbrisati",
    client_name: "Ime klijenta",
    created_at: "Stvoreno u",
    updated_at: "Ažurirano u",
    update: "Ažuriraj",
    add_room: "Dodaj sobu",
    room_type: "Vrsta sobe",
    max_occupancy: "Maksimalna popunjenost",
    add_room_type: "Dodajte vrstu sobe",
    is_succesfully_saved: "je uspješno spremljeno",
    property_name: "Ime",
    legal_entity: "Legalno ime",
    legal_entity_id: "Jedinstveni identifikator",
    address: "Adresa",
    check_in: "Prijaviti se",
    check_out: "Provjeri",
    pet_price: "Cijena kućnog ljubimca",
    type: "Tip",
    amount: "Iznos",
    new: "Novi",
    add_expense: "Dodajte trošak",
    expense_saved: "Trošak je spremljen",
    datagrid: enUS,
    reservation_updated: "Rezervacija je ažurirana",
    name_prompt: "Napišite ime klijenta",
    invoice: "Dostavnica",
    view: "pogled",
    is_succesfully_updated: "je uspješno ažuriran",
    reservation: "Rezervacija",
    send: "Poslati",
    issue_date: "Datum izdavanja",
    due_date: "Datum dospijeća",
    for: "za",
    payment: "Plaćanje",
    overnight_stays: "Noćenje",
    number_of_people: "Broj ljudi",
    price_of_overnight_stay: "Cijena po noćenju",
    total: "Ukupno",
    to: "do",
    from: "iz",
    pet_fee: "Naknada za kućne ljubimce",
    discount: "Popust",
    no_discount: "Nema popusta",
    remaining: "Preostalo",
    invoice_message:
      "Hvala vam što ste odabrali ostati s nama, nadamo se da ćemo vas opet dočekati u budućnosti.",
    room_number: "Broj sobe",
    number_positive: "mora biti jednak ili veći od 0",
    version: "verzija",
    new_version_notification:
      "Nova verzija dostupna! Molimo osvježite stranicu.",
    refresh: "Osvježiti",
    release_notes: "Bilješke o izdanju",
    is_succesfully_deleted: "je uspješno izbrisan",
    print: "Ispisati",
    room: "soba",
  },
};
