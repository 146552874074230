import React from "react";
import { AppShell } from "./src/components/AppShell";
import PrivateLayout from "./src/components/Private";

export const wrapRootElement = ({ element }) => {
  return <AppShell>{element}</AppShell>;
};

export const wrapPageElement = ({ element, props }) => {
  const { path } = props;
  if (path === "/login" || path === "/404" || path === "/invoice/:id") {
    return element;
  }
  return <PrivateLayout>{element}</PrivateLayout>;
};
