import * as React from "react";
import { useAuthorizer } from "@authorizerdev/authorizer-react";
import { navigate } from "gatsby";

export default function PrivateLayout({ children }) {
  const { user, loading } = useAuthorizer();
  React.useEffect(() => {
    if (!loading && !user) {
      navigate("/login");
    }
  }, [loading, user]);

  return !loading && user && children;
}
