import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import ws from "ws";
import isNode from "is-node";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";
import { AuthorizerClient } from "../authorizer/Authorizer";
import fetch from "cross-fetch";

if (process.env.GATSBY_API_URL_WS === undefined) {
  throw new Error("GATSBY_API_URL_WS is missing");
}
if (process.env.GATSBY_API_URL_HTTP === undefined) {
  throw new Error("GATSBY_API_URL_HTTP is missing");
}
if (process.env.GATSBY_HASURA_ADMIN_SECRET === undefined) {
  throw new Error("GATSBY_HASURA_ADMIN_SECRET is missing");
}

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.GATSBY_API_URL_WS,
    webSocketImpl: isNode ? ws : null,
    connectionParams: async () => {
      const { id_token } = await AuthorizerClient.getSession();
      return {
        headers: {
          Authorization: `Bearer ${id_token}`,
        },
      };
    },
  })
);

const wsAdminLink = new GraphQLWsLink(
  createClient({
    url: process.env.GATSBY_API_URL_WS,
    webSocketImpl: isNode ? ws : null,
    connectionParams: async () => {
      return {
        headers: {
          "x-hasura-admin-secret": process.env.GATSBY_HASURA_ADMIN_SECRET,
        },
      };
    },
  })
);

const authLink = setContext(async (_, { headers }) => {
  const { id_token } = await AuthorizerClient.getSession();
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${id_token}`,
    },
  };
});

const httpLink = new HttpLink({
  uri: process.env.GATSBY_API_URL_HTTP,
  fetch,
});

const adminLink = new HttpLink({
  uri: process.env.GATSBY_API_URL_HTTP,
  headers: { "x-hasura-admin-secret": process.env.GATSBY_HASURA_ADMIN_SECRET },
  fetch,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const splitAdminLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsAdminLink,
  adminLink
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({ addTypename: false }),
});

export const adminSubClient = new ApolloClient({
  link: splitAdminLink,
  cache: new InMemoryCache({ addTypename: false }),
});

export const adminClient = new ApolloClient({
  link: adminLink,
  cache: new InMemoryCache(),
});
