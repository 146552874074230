import { useAuthorizer } from "@authorizerdev/authorizer-react";
import dayjs from "dayjs";
import React from "react";
import { translations } from "../translations";
import {
  useUpdateUserSelectedPropertyMutation,
  useUserPropertiesSubscription,
  PropertyFragment,
} from "../__generated__/types";

type Context =
  | {
      selected: string;
      property: PropertyFragment[] | undefined;
      setSelected: (propertyId: string) => void;
      selected_locale: string;
      currency_code: string;
    }
  | undefined;

const SelectedPropertyContext = React.createContext<Context>(undefined);

export const useSelectedPropertyContext = () => {
  const context = React.useContext(SelectedPropertyContext);
  if (context === undefined) {
    throw new Error(
      "useSelectedPropertyContext needs to be wrapped with SelectedPropertyProvider"
    );
  }
  return context;
};

export const SelectedPropertyProvider = ({ children }) => {
  const { user, loading } = useAuthorizer();

  const { data, loading: propertyLoading } = useUserPropertiesSubscription({
    variables: { id: user?.id },
    skip: !loading && user ? false : true,
  });
  const [updateUserSelectedProperty] = useUpdateUserSelectedPropertyMutation();
  React.useEffect(() => {
    if (data?.user_by_pk?.selected_locale) {
      translations[data?.user_by_pk?.selected_locale].dayjs_locale.then(() => {
        dayjs.locale(
          translations[data?.user_by_pk?.selected_locale].dayjs_localeCode
        );
      });
    }
  }, [data?.user_by_pk?.selected_locale]);
  if (loading || propertyLoading) return null;
  return (
    <SelectedPropertyContext.Provider
      value={{
        selected: data?.user_by_pk?.selected_property_id,
        setSelected: (selected) =>
          updateUserSelectedProperty({
            variables: { id: user?.id, selected_property_id: selected },
          }),
        property: data?.user_by_pk?.properties,
        selected_locale: data?.user_by_pk?.selected_locale || "en-US",
        currency_code:
          data?.user_by_pk?.properties.find(
            (p) => p.id === data?.user_by_pk?.selected_property_id
          )?.currency_code || "EUR",
      }}
    >
      {children}
    </SelectedPropertyContext.Provider>
  );
};
